import * as React from 'react'
import {useLocation} from 'react-router-dom'
import { selectCurrentToken} from '../Login_Registration/authSlice'
import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

const UserAuth =()=>
{ 
   const token=useSelector(selectCurrentToken)
   const location=useLocation()
   if(token)
   {
    const decoded=jwtDecode(token)
    const {UserName,UserEmail}=decoded.userInfo
    return { UserName , UserEmail, token}
   }
   else
   {
   return { UserName:'',UserEmail:'',token:''}
   }
   
}
export default UserAuth