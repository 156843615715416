import React, { useEffect } from "react";
import {Box,Button,TextField,Paper, Stack,Typography,MenuItem} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DatePicker } from "@mui/x-date-pickers";
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DescriptionIcon from '@mui/icons-material/Description';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { green, blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import axios from "../../axiosCustomInstance";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
function  RFI_Client_Input_Update()
{
const PD= new Date()
const today=new Intl.DateTimeFormat('en-US',{dateStyle:'short'}).format(PD)
const[Form_Value,Set_Form_Value]=useState({Opportunity_Name:"",Solicitation_Number:"",Brief:"",Contract_Vehicle:"GSA",
Contracting_Officer_Name:"",Contracting_Specialist:"",KO_email:"",KO_phone:"",KO_address:"",Published_date:null,Due_date:null,Estimated_$_Amount:""
,Expected_RFP:0,Incumbent_Info:"",Proposal_Stage:"",Team_Support:"",Teaming_partners_Vendors:"",Potential_Roadblocks:"",Govwin_Link:"",Sam_gov_link:"",
Created_Date:today,Last_Updated_Date:today,
Updated_By:"TBD",
Version_No:parseInt(0),
Updating_Comments:"RFI Created"})
const [searchParams]=useSearchParams()
const [RFI_Data,setRFI_Data]=useState({})
const [dis,setDis]=useState(false)
const navigate=useNavigate()
const[rfiMssge,setRFIMssge]=useState('')
const [successDialog,setSuccessDialog]=useState(false)
const [errorDialog,setErrorDialog]=useState(false)
const [open, setOpen] = useState(false);
const AxiosAPIInstance=AxiosFunction()

useEffect(()=>{
async function fetchData(){
  await AxiosAPIInstance.get("/RFIsSearch/SolicitationNumber",{params:{Solicitation_Number:searchParams.get("RSocNum")}}).then(async(result)=>{
    setRFI_Data(result?.data[0])
  })
}
fetchData();
},[searchParams])

useEffect(()=>{
  Set_Form_Value(RFI_Data)
},[RFI_Data?.Solicitation_Number])



const handleClose = (e) => {
  setOpen(false);
  setErrorDialog(false)
  setSuccessDialog(false)
};
const HandleSubmit = async(event) => {
    setDis(true)
    event.preventDefault()
    await AxiosAPIInstance.patch("/RFIs/UpdateRFI",Form_Value).then(
        (response)=>{
           setRFIMssge(response?.data)
           setErrorDialog(false)
           setSuccessDialog(true)
           setDis(false)
        }
    ).catch((error)=>{
      setDis(false)
      setRFIMssge(error.response?.data)
      setErrorDialog(true)
      setSuccessDialog(false)
    })
}
return(
  <>
  {<ApplicationBar></ApplicationBar>}
    {open===true&&(<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to exit this page?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the changes will be lost if you decide to exit this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={handleClose} href="/Dashboard">Yes</Button>
          <Button name="No" onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfiMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          navigate("/Dashboard/RFIsSearch", { replace: true });
          setDis(false)
          }
          }>OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfiMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/RFInput">OK</Button>
        </DialogActions>
      </Dialog>)}
  <Stack component="form" onSubmit={HandleSubmit} sx={{marginTop:"50px",marginLeft:"370px",padding:"30px"}}>
 <Stack direction="column" spacing={3} sx={{marginTop:"0px", width:"960px"}}>
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold",}} > Request For Information(RFI)</Typography>
        <Stack direction="row" spacing={3}>
        <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="Opportunity Name" value={Form_Value.Opportunity_Name} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Opportunity_Name:e.target.value})}></TextField>
        <TextField  size="small"sx={{width:"250px"}} label="Solicitation Number" InputProps={{readOnly:true}} value={Form_Value.Solicitation_Number} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Solicitation_Number:e.target.value})}></TextField>
        <TextField   size="small"sx={{width:"250px"}} multiline={true} rowsMax={20} label="Brief" value={Form_Value.Brief} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Brief:e.target.value})}  InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
        <Select size="small"
          labelId="Contract-Vehicle"
          id="Contract-Vehicle-id"
          value={Form_Value.Contract_Vehicle}
          label="Contract Vehicle"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Contract_Vehicle:e.target.value})}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="G" value={"GSA"}>GSA</MenuItem>
          <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
          <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Direct Inquiry</MenuItem>
          <MenuItem size="small" name="CR" value={"Commercial/Retail"}>Commercial/Retail</MenuItem>
        </Select>
      </FormControl>
        <TextField   size="small" sx={{width:"250px"}} label="CO's name" value={Form_Value.Contracting_Officer_Name} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Contracting_Officer_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
            <TextField  size="small" sx={{width:"250px"}} label="Contracting Specialist" value={Form_Value.Contracting_Specialist} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Contracting_Specialist:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={3} >
        <TextField  size="small" type="email" sx={{width:"250px"}} label="CO's email" value={Form_Value.KO_email} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,KO_email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField  size="small" sx={{width:"250px"}} label="CO's phone" value={Form_Value.KO_phone} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,KO_phone:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField  size="small" multiline={true} rowsMax={10} sx={{width:"250px"}} label="CO's address" required={true} value={Form_Value.KO_address} onChange={(e)=>Set_Form_Value({...Form_Value,KO_address:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
    </Stack>
    <Stack direction="row" spacing={3}>
        <DatePicker label="Published date" sx={{width:"250px"}}  value={Form_Value.Published_date} maxDate={new Date()} renderInput={(params)=><TextField  size="small" required={true} sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Published_date:value})}></DatePicker>
        <DatePicker label="Due date" sx={{width:"250px"}} value={Form_Value.Due_date} minDate={Form_Value.Published_date} renderInput={(params)=><TextField required={true}  size="small" variant="outlined" sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Due_date:value})}></DatePicker>
        <TextField   size="small" sx={{width:"250px"}} type="number" required={true} label="Estimated Amount" value={Form_Value.Estimated_$_Amount} onChange={(e)=>Set_Form_Value({...Form_Value,Estimated_$_Amount:e.target.value})} inputProps={{min:"0"}} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
        <TextField  size="small" sx={{width:"250px"}} multiline={true} required={true} rowsMax={20} label="Incumbent Info" value={Form_Value.Incumbent_Info} onChange={(e)=>Set_Form_Value({...Form_Value,Incumbent_Info:e.target.value})}></TextField>
        <TextField   size="small" sx={{width:"250px"}} label="Proposal Stage" required={true} value={Form_Value.Proposal_Stage} onChange={(e)=>Set_Form_Value({...Form_Value,Proposal_Stage:e.target.value})}></TextField>
        <TextField  size="small" sx={{width:"250px"}} multiline={true} required={true} rowsMax={20} label="Team Support" value={Form_Value.Team_Support} onChange={(e)=>Set_Form_Value({...Form_Value,Team_Support:e.target.value})}></TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
        <TextField  size="small" sx={{width:"250px"}} multiline={true} required={true} rowsMax={20} label="Teaming partners/Vendors" value={Form_Value.Teaming_partners_Vendors} onChange={(e)=>Set_Form_Value({...Form_Value,Teaming_partners_Vendors:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Diversity3Icon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField   size="small" sx={{width:"250px"}} multiline={true} required={true} rowsMax={20} label="Potential Roadblocks" value={Form_Value.Potential_Roadblocks} onChange={(e)=>Set_Form_Value({...Form_Value,Potential_Roadblocks:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RemoveRoadIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField  size="small" sx={{width:"250px"}} label="Govwin Link" required={true} type="url" value={Form_Value.Govwin_Link} onChange={(e)=>Set_Form_Value({...Form_Value,Govwin_Link:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AddLinkIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
        <TextField   size="small" sx={{width:"250px"}}label="Sam.gov link" type="url" required={true} value={Form_Value.Sam_gov_link} onChange={(e)=>Set_Form_Value({...Form_Value,Sam_gov_link:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AddLinkIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField   size="small" sx={{width:"250px"}} label="Expected RFP" required={true} value={Form_Value.Expected_RFP} onChange={(e)=>Set_Form_Value({...Form_Value,Expected_RFP:e.target.value})}></TextField>
        <TextField  size="small" multiline={true} rowsMax={10} minRows={3} sx={{width:"250px"}} label="Updating Comments" required={true} value={Form_Value.Updating_Comments} onChange={(e)=>Set_Form_Value({...Form_Value,Updating_Comments:e.target.value})}>
        </TextField>
        {/* <FormGroup>
        <FormControlLabel  control={<Checkbox defaultChecked />} label="Generate a PDF" sx={{width:"300px"}} />
        </FormGroup> */}
        <Stack sx={{width:"250px"}}></Stack>
        </Stack >
        <Button  sx={{width:"250px"}} variant="contained" disabled={dis} size="small" color="error" type="submit" >UPDATE</Button>
        </Stack>  
</Stack>
</>
)
}
export default RFI_Client_Input_Update