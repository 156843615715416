import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '45%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize:9
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    UM: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    SKU:{
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    SNo:{
        width: '5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    amount: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
});

const InvoiceTableRow = ({ invoice }) => {
    const rows = invoice.items?.map((item,index) =>
        <View style={styles.row} >
            <Text style={styles.SNo}>{index+1}</Text>
            <Text style={styles.SKU}>{item.SKU_ID}</Text>
            <Text style={styles.description}>{item.SKU_Description}</Text>
            <Text style={styles.qty}>{item.QTY}</Text>
            <Text style={styles.UM}>{item.Unit_Of_Measure}</Text>
            <Text style={styles.rate}>{'$ '+parseFloat(item.Customer_Unit_Cost).toFixed(3)}</Text>
            <Text style={styles.amount}>{'$ '+parseFloat(item.QTY * item.Customer_Unit_Cost).toFixed(3)}</Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow;