import * as React from 'react'
import { useSelector } from 'react-redux'
import {useLocation,Navigate,Outlet} from 'react-router-dom'
import { selectCurrentToken, selectCurrentUser} from '../Login_Registration/authSlice'
import jwtDecode from 'jwt-decode'
const RequireAuth=()=>
{
    const token=useSelector(selectCurrentToken);
    const user=useSelector(selectCurrentUser);
    const location=useLocation();
    const decoded= jwtDecode(token);
    const {UserName,UserEmail}=decoded?.userInfo;
    
    
    return(
        UserEmail && token?<Outlet/>:<Navigate to="/" state={{from:location}} replace/>
    )

    
}
export default RequireAuth