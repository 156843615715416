import React from "react";
import { Page, Document, StyleSheet, Image, View } from "@react-pdf/renderer";
import InvoiceTitle from "./Heading";
import InvoiceNo from "./Number";
import InvoiceThankYouMsg from "./Thank-You";
import InvoiceItemsTable from "./Items-Table";
import ShippingItemsTable from "./Shipping-Table";
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 50,
        paddingRight: 50,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
  
});

const PdfDocument = ({ invoicedata}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="landscape" >
                <InvoiceTitle invoice={invoicedata} />
                <InvoiceNo invoice={invoicedata} />
                <ShippingItemsTable invoice={invoicedata}></ShippingItemsTable>
                <InvoiceItemsTable invoice={invoicedata} />
            </Page>
        </Document>
    );
}

export default PdfDocument;