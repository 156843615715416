import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        fontSize: 9,
       
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        fontSize: 9,
    },
    headerContainer: {
        justifyContent: 'flex-start',
        width: '45%',
        borderStyle:'solid',
        borderColor:'black',
        fontSize: 9,
    },
    billTo: {
        fontSize: 10,
        fontWeight:'heavy'

    },
    invoiceDate: {
        fontSize: 9,
        fontStyle: 'bold',
    },
    label: {
        width: 60,
        fontSize: 9,
    },
    Total_Con:{
        flexDirection: 'row',
        marginTop:"30px"
    },
    Date_Inv_Con:{
        flexDirection: 'column',
        marginRight:'5x',
        borderStyle:'solid',
        borderColor:'black',
        width: '15%',
    },
    QuotedBy: {
        width: '40%',
        borderStyle:'solid',
        borderColor:'black',
        fontSize: 9,
        marginLeft:'0px'
    },
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={styles.Total_Con} >
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>CUSTOMER DETAILS :</Text>
        <Text>{"AGENCY NAME: "+invoice.Shipping_Details.Organization_Name}</Text>
        <Text>{"POC: "+invoice.KO_name}</Text>
        <Text>{"ADDRESS: "+invoice.KO_address}</Text>
        <Text>{"EMAIL: "+invoice.KO_email}</Text>
        <Text>{"Ph: "+invoice.KO_phone}</Text>
    </View>
    <View style={styles.QuotedBy}>
        <Text style={styles.billTo}>SHIP TO :</Text>
        <Text>{invoice.Shipping_Details.Customer_Name}</Text>
        <Text>{invoice.Shipping_Details.Customer_Address}</Text>
        <Text>{invoice.Shipping_Details.Customer_Email}</Text>
        <Text>{invoice.Shipping_Details.Customer_Phone}</Text>
    </View>
    <View style={styles.Date_Inv_Con}>
    <Text style={styles.billTo}><b>QUOTE INFO:</b></Text>
        <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>QUOTE#: </Text>
            <Text style={styles.invoiceDate}>{invoice.invoice_num}</Text>
        </View >
        <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>DATE: </Text>
            <Text >{invoice.trans_date}</Text>
        </View >
    </View>
        </View>
    </Fragment>
);

export default InvoiceNo;