import React from "react";
import {Box,Button, Stack,Typography,IconButton,Grid} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import RFQForm from '../Images/RFQ-Form.png'
import RFI from '../Images/RFI.png'
import POs from '../Images/POs.png'
import SKUs from '../Images/SKUs.png'
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import { height, maxHeight } from "@mui/system";
const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[300],
      },
    },
  });
function  HomePage()
{ 
  const navigate=useNavigate()
  return (
    <>
     {<ApplicationBar></ApplicationBar>}
     <Grid container sx={{bgcolor:[theme.palette.secondary.main],height:"720px"}}>
    <Stack direction="column" sx={{marginLeft:'150px',marginTop:'90px'}}>
      <Stack direction="row" spacing={10}>
    <Card sx={{ width:250,":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
      <CardMedia
        component="img"
        height="140"
        image={RFQForm}
        alt="green iguana"
  sx={{margin:'auto'}}/>
        
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" sx={{textAlign:'center'}}>
        REQUEST FOR A QUOTE
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
          <b>Use this tab for</b><br></br>
          Creating a New RFQ,<br></br>
          Updating an existing RFQ,<br></br>
          Get all the Quotes
        </Typography>
      </CardContent>
      <CardActions sx={{paddingTop:12,paddingBottom:6,paddingLeft:6,paddingRight:6}}>
      <Stack direction="column" spacing={2} >
        <Button  onClick={(e)=>{e.preventDefault();navigate("/Dashboard/CreateRFQ")}} size="small" variant="contained" color="primary">CREATE A QUOTE</Button>
        <Button  onClick={(e)=>{e.preventDefault();navigate("/Dashboard/RFQsSearch")}}  sx={{textAlign:"center"}} size="small" variant="outlined" color="primary">Retrieve & UPDATE</Button>
        </Stack>
      </CardActions>

    </Card>
    <Card sx={{ width:250,":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
      <CardMedia
        component="img"
        height="140"
        image={RFI}
        alt="green iguana"/>
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" sx={{textAlign:'center'}}>
         REQUEST FOR INFORMATION
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
         <b>Use this tab for </b><br></br>
          Creating a New RFI/Sources Sought,<br></br>
          Updating an existing RFI/Sources Sought,<br></br>
          Get all RFIs/Sources Sought  
        
        </Typography>
      </CardContent>
      <CardActions sx={{paddingTop:4,paddingBottom:6,paddingLeft:6,paddingRight:6}}>
        <Stack direction="column" spacing={2}>
        <Button size="small"  onClick={(e)=>{e.preventDefault();navigate("/Dashboard/CreateRFI")}} variant="contained" color="primary">CREATE</Button>
        <Button size="small"  onClick={(e)=>{e.preventDefault();navigate("/Dashboard/RFIsSearch")}} sx={{textAlign:"center"}} variant="outlined" color="primary">Retrieve & UPDATE</Button>
        </Stack>
      </CardActions>
    </Card>
    <Card sx={{ width: 250,":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
      <CardMedia
        component="img"
        height="140"
        image={POs}
        alt="green iguana"/>
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" sx={{textAlign:'center'}}>
         PURCHASE ORDERS
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Use this tab to </b><br></br>
          Get all POs by Date/Date Range,
          Purchase Order by RFQ ID
        </Typography>
      </CardContent>
      <CardActions sx={{paddingTop:14.5,paddingBottom:6,paddingLeft:7,paddingRight:6}}>
      <Stack direction="column" spacing={2}>
        <Button size="small" onClick={(e)=>{e.preventDefault();navigate("/Dashboard/POsSearch")}} variant="outlined" color="primary" sx={{textAlign:'center'}}>GET POs by Different Search Filters</Button>
        </Stack>
      </CardActions>
    </Card>
    <Card sx={{ width: 250,":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
      <CardMedia
        component="img"
        height="140"
        image={SKUs}
        alt="green iguana"/>
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" sx={{textAlign:'center'}}>
       SKUs
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Use this tab to </b><br></br>
          Get all the SKUs by Vendor Name,<br></br>
          Bulk Upload using an Excel format
        </Typography>
      </CardContent>
      <CardActions sx={{paddingTop:9.5,paddingBottom:6,paddingLeft:8,paddingRight:6}}>
      <Stack direction="column" spacing={2}>
        <Button size="small"  variant="outlined" color="primary">GET by Vendor Name</Button>
        <Button size="small"  onClick={(e)=>{e.preventDefault()}} variant="outlined" color="secondary"> Bulk Upload</Button>
        {/* <Button size="small" variant="contained" color="primary">GET all PO<Typography variant="h10">s</Typography></Button> */}
        </Stack>
      </CardActions>
    </Card>
    </Stack>
    </Stack>
    </Grid>
    </>
  );
  }
  export default HomePage
