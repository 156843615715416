import React from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState,useEffect} from "react";
import {Button,TextField,Stack,MenuItem,InputAdornment} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PdfDocument from "../../PDF/Main";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Alert } from '@mui/material';
import ApplicationBar from "../../AppBar/ApplicationBar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate } from "react-router-dom";
import FinanceRow from "./TableHelpers/financeTableRowHelper";
import AxiosFunction from "../../../axiosCustomInstance";
import { selectCurrentToken} from '../../Login_Registration/authSlice'
import { useSelector } from 'react-redux';

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const color1=blueGrey[500]
const color2=blueGrey[50]

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Row(props) {
  const PD= new Date()
  const { row} = props;
  const [open, setOpen] = React.useState(false);
  const[clicked,setClicked]=useState(false);
 
{/*---------------------------------------------Defining variables for PDF data---------------------------------------------------------------------*/}
const [InvoiceData,setInvoiceData]=useState({ 
  invoice_no:"",
  KO_name:"",
  KO_email:"",
  KO_phone: "",
  KO_address:"",
  trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
  due_date: "",
  Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
  items: [{}],
  Tax:row.Tax,
  Shipping_Cost:row.Shipping_Cost,
  Total_Cost:row.Actual_Total_Cost_After_Margin,
  Shipping_Details:{
    Organization_Name:"",
    KO_name:"",
    Sales_Head_Info:{},
    Customer_Name:"",
    Customer_Email:"",
    Customer_Phone:"",
    Customer_Address:"",
    Shipping_Method:"",
    Shipping_Terms:"",
    Estimated_Delivery:"",
    Payment_Terms:"",
    Notes:""}})
const HandlePDFClick=(e)=>{
  setInvoiceData({...InvoiceData,
    invoice_num:String(row.Invoice_No)+"R"+String(row.Version_No),
    KO_name: row.KO_name,
    KO_email:row.KO_email,
    KO_phone: row.KO_phone,
    KO_address:row.KO_address,
    trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
    due_date: row.Due_date,
    Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: row.Purchase_Orders,
    Tax:row.Tax,
    Shipping_Cost:row.Shipping_Cost,
    Total_Cost:row.Actual_Total_Cost_After_Margin,
    Shipping_Details:{
      Organization_Name:row.Organization_Name,
      KO_name:row.KO_name,
      Sales_Head_Info:{Name:row.Sales_Head_Name,Email:row.Sales_Head_Email,Phone:row.Sales_Head_Ph},
      Customer_Name:row.Customer_Name,
      Customer_Email:row.Customer_Email,
      Customer_Phone:row.Customer_Phone,
      Customer_Address:row.Customer_Address,
      Shipping_Method:row.Shipping_Method,
      Shipping_Terms:row.Shipping_Terms,
      Estimated_Delivery:row.Estimated_Delivery,
      Payment_Terms:row.Payment_Terms,
      Notes:row.Notes}
  })
  setClicked(true)
}

  return (
    <React.Fragment>
      <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {e.preventDefault();setOpen(!open)}}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center" scope="row">{row.Opportunity_Name}
        </TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.RFQ_ID}</TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Contract_Vehicle}</TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.KO_name}<br></br><b>Organization:</b>{row.Organization_Name}<br></br><b>Address:</b>{row.KO_address}<br></br>
        <b>Email:</b>{row.KO_email}<br></br><b>Phone:</b>{row.KO_phone}</Box></TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.Customer_Name}<br></br><b>Address:</b>{row.Customer_Address}<br></br>
        <b>Email:</b>{row.Customer_Email}<br></br><b>Phone:</b>{row.Customer_Phone}</Box></TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="Left">{row.Sales_Head_Name+", "+row.Sales_Head_Email+", "+row.Sales_Head_Ph}<br></br>
        <b>Created Date :</b>{String(row.Created_Date).split('T')[0]}<br></br>
        <b>Last Updated Date :</b>{String(row.Last_Updated_Date).split('T')[0]}</TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">
        {row.RFQ_Status==="PENDING"&& <Button color="warning" type="text" size="small" variant="contained" sx={{height:"25px",fontSize:"10px",}}>{row.RFQ_Status}</Button>}
        {row.RFQ_Status==="WON"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small" color="success" type="text" variant="contained">{row.RFQ_Status}</Button>}
        {row.RFQ_Status==="LOST"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small"  color="error" type="text" variant="contained">{row.RFQ_Status}</Button>}
        <br></br><br></br>
        <Typography variant='h7' sx={{width:"50px",fontSize:"14px"}}><b>Rev:</b> {row.Version_No}</Typography><br></br>
        <IconButton color="secondary" type="text" size="small" variant="contained" sx={{marginTop:"10px",height:"35px",fontSize:"10px"}}><PictureAsPdfIcon onClick={(e)=>{
          e.preventDefault();
          HandlePDFClick();}}></PictureAsPdfIcon></IconButton>
        {clicked===true &&(<PDFDownloadLink onClick={HandlePDFClick} component="button" color="secondary"
            document={<PdfDocument invoicedata={InvoiceData}/>}
            fileName={row.Opportunity_Name+" "+row.RFQ_ID}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : "Download"
            }
          </PDFDownloadLink>)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1,}}>
              {/* <Typography variant="h6">
                SKU INFO
              </Typography> */}
              <Table aria-label="purchases" sx={{border:1,borderColor:'text.main'}}>
                <TableHead sx={{backgroundColor:theme.palette.secondary.main,}}>
                  <TableRow>
                  <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}}>Vendor/Partner Name</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}}>SKU ID</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">SKU Description</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">QTY</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">UM</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Aspetto's Unit Cost($)</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Margin(%)</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Customer's Unit Cost($)</TableCell>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Total SKU Cost($)</TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row["Purchase_Orders"]?.map((Purchase_OrderROW,index) => (
                    <TableRow key={index}>
                      <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Vendor_Partner_name}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.SKU_ID}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.SKU_Description}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.QTY}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Unit_Of_Measure}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Aspetto_Unit_Cost}</TableCell>
                      <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Margin_Percent}</TableCell>
                      <TableCell  sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Customer_Unit_Cost}</TableCell>
                      <TableCell  sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Total_SKU_Cost}</TableCell>
              
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">Shipping Cost</TableCell>
                  <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Shipping_Cost}</TableCell>
                  <TableCell></TableCell>
                 
                  </TableRow>
                  <TableRow>
                  <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">Tax</TableCell>
                  <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Tax}</TableCell>
                  <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">TOTAL</TableCell>
                  <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Actual_Total_Cost_After_Margin}</TableCell>
                  <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function POs_DateRange() {
  const [GetClick,SetGetClick]=useState(false);
  const [totalData,setTotalData]=useState([{}])
  const [salesHeadName,setSalesHeadName]=useState("")
  const [salesHeadNameChecked,setSalesHeadNameChecked]=useState(false)
  const [opportunityName,setOpportunityName]=useState("")
  const [opportunityNameChecked,setOpportunityNameChecked]=useState(false)
  const [getRFQID,setgetRFQID]=useState("")
  const [getRFQIDChecked,setGetRFQIDChecked]=useState(false)
  const [DateValue, SetDateValue]=useState({From_Date: new Date(),To_Date: new Date()})
  const [getDateRangeChecked,setGetDateRangeChecked]=useState(false)
  const [fieldchecked,setFieldChecked]=useState("");
  const [open1, setOpen1] = useState(false);
  const [stus,setStus]=useState("WON")
  const [dis,setDis]=useState(false)
  const [oppError,setOppError]=useState(false)
  const [dateError,setDateError]=useState(false)
  const [salesHeadError,setSalesHeadError]=useState(false)
  const [rfqIDError,setRFQIDError]=useState(false)
  const [searchFilterError,setSearchFilterError]=useState(false)
  const [oppErrorMsge,setOppErrorMsge]=useState("")
  const [dateErrorMsge,setDateErrorMsge]=useState("")
  const [salesHeadErrorMsge,setSalesHeadErrorMsge]=useState("")
  const [rfqIDErrorMsge,setRFQIDErrorMsge]=useState("")
  const [searchFilterErrorMsge,setSearchFilterErrorMsge]=useState("")
  let [usersData,setUsersData]=useState([{User_email:""}])
  const navigate=useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const AxiosAPIInstance=AxiosFunction()

  //-----------------------------------------------------------------------------------------------------------------


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  React.useEffect(()=>{
    const fetchUsersData=async()=>{
          await AxiosAPIInstance.get("/UserAuth/users").then((result)=>{
            const users=[...result?.data]
            setUsersData(users)
            setSalesHeadError(false)
            setSalesHeadErrorMsge("")
          }).catch((error)=>{
            setSalesHeadError(true)
            setSalesHeadErrorMsge(error?.response.data)
        })
    }
    fetchUsersData();
  
  },[setSalesHeadError]);
    
  const handleClose = (e) => {
    setOpen1(false);
    setRFQIDError(false);
    setOppError(false);
    setSalesHeadError(false)
    setSearchFilterError(false)
    setDateError(false)
    setOppErrorMsge("");
    setSalesHeadError("");
    setRFQIDErrorMsge("")
    setDateErrorMsge("")
  };
  useEffect(()=>{
    setTotalData([{}]);
    SetGetClick(false);
  },[stus])
  
  const handleRFQIDChange=(e)=>{
    setGetRFQIDChecked(e.target.checked);
    SetGetClick(false);
    if(e.target.checked===true)
    { 
      setOpportunityNameChecked(false);
      setSalesHeadNameChecked(false);
      setGetDateRangeChecked(false);
      setFieldChecked("RFQID");
      SetDateValue({From_Date: new Date(),To_Date: new Date()})
      setSalesHeadName("");
      setOpportunityName("");
    }
    else
    {
      setFieldChecked("");
      setgetRFQID("");
   
    }

  }
  const handleOpporNameChange=(e)=>{
    SetGetClick(false);
    setOpportunityNameChecked(e.target.checked);
    if(e.target.checked===true)
    { 
      setGetRFQIDChecked(false);
      setSalesHeadNameChecked(false);
      setGetDateRangeChecked(false)
      setFieldChecked("OpportunityName");
      setgetRFQID("");
      setSalesHeadName("");
      SetDateValue({From_Date: new Date(),To_Date: new Date()})
      
    }
    else
    {
      setFieldChecked("");
      setOpportunityName("")
    }

  }
  const handleSalesHeadEmailChange=(e)=>{
    setSalesHeadNameChecked(e.target.checked);
    SetGetClick(false);
    if(e.target.checked===true)
    { 
      setGetRFQIDChecked(false);
      setOpportunityNameChecked(false)
      setGetDateRangeChecked(false)
      setFieldChecked("SalesHead");
      setgetRFQID("");
      setOpportunityName("");
      SetDateValue({From_Date: new Date(),To_Date: new Date()});
    }
    else{
      setFieldChecked("");
      setSalesHeadName("")
    }
  }
  const handleDateRangeChange=(e)=>{
    setGetDateRangeChecked(e.target.checked);
    SetGetClick(false);
    if(e.target.checked===true)
    { 
      setGetRFQIDChecked(false);
      setSalesHeadNameChecked(false)
      setOpportunityNameChecked(false)
      setFieldChecked("DateSearch");
      setgetRFQID("");
      setOpportunityName("");
      setSalesHeadName("")
    }
    else
    {
      setFieldChecked("");
    }
  }
  const Get_Data_Click=async (e)=>{
    e.preventDefault();
    setDis(true)
   if(fieldchecked!=='')
   {
    if(getRFQIDChecked===true)
        {  
          if(getRFQID!=='')
        {
          await AxiosAPIInstance.get("/RFQsSearch/RFQID",{params:{RFQ_ID:getRFQID,RFQ_Status:stus}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
              setTotalData(response?.data)
              setDis(false)
              SetGetClick(true)
              }
              else
              {
                SetGetClick(false)
                setRFQIDError(true)
                setRFQIDErrorMsge("No Records Found")
              }
            }
          ).catch((err)=>{
            setRFQIDError(true)
            setRFQIDErrorMsge(err.response?.data)
            setTotalData([{}]);
            setDis(false)
          })
        }
        else {
          setRFQIDError(true)
          setRFQIDErrorMsge("RFQ ID is Required")
          setTotalData([{}]);
          setDis(false)
            }
        }
    else if(fieldchecked==='OpportunityName')
        {
          if(String(opportunityName).trim().length>=3)
          {
          await AxiosAPIInstance.get("/RFQsSearch/OpportunityName",{params:{Opportunity_Name:String(opportunityName).trim(),RFQ_Status:stus}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
                SetGetClick(true);
                setTotalData(response?.data)
                setDis(false)
              }
              else{
                SetGetClick(false)
                setOppError(true)
                setOppErrorMsge("No Records Found")
              }
             
            }
          ).catch((err)=>{
            SetGetClick(false)
            setOppError(true);
            setOppErrorMsge(err.response?.data);
            setTotalData([{}]);
            setDis(false)
          
          })
        }
        else
        {
          SetGetClick(false);
          setTotalData([{}]);
          setDis(false);
          setOppError(true);
          setOppErrorMsge("Opportunity Name should contain atleast 3 letters");;
         
        }
        }
    else if(salesHeadNameChecked===true)
        {
          if(salesHeadName!=='')
          {
          await AxiosAPIInstance.get("/RFQsSearch/SalesHead",{params:{Sales_Head_Email:String(salesHeadName).trim(),RFQ_Status:stus}}).then(
            (response)=>{
              if(response?.data.length>0){
              SetGetClick(true);
              setTotalData(response?.data)
              setDis(false)
              }
              else
              {
                SetGetClick(false)
                setSalesHeadError(true)
                setSalesHeadErrorMsge("No Records Found")
              
              }
            }
          ).catch((err)=>{
            SetGetClick(false)
            setSalesHeadError(true)
            setSalesHeadErrorMsge(err.response?.data)
            setTotalData([{}]);
            setDis(false)
          })
        }
        else {
              SetGetClick(false)
              setSalesHeadError(true)
              setSalesHeadErrorMsge("Sales Head Email is Required")
              setDis(false)
              setTotalData([{}]);
              }
        }
        else if(fieldchecked=='DateSearch')
        {
          await AxiosAPIInstance.get("/RFQsSearch/DateSearch",{params:{From:DateValue.From_Date,To:DateValue.To_Date,RFQ_Status:stus}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
              setTotalData(response?.data)
              setDis(false)
              SetGetClick(true);
              }
              else{
                SetGetClick(false);
                setDateError(true)
                setDateErrorMsge("No Records Found")
                
              }
            }
          ).catch((err)=>{
            SetGetClick(false)
            alert(err.response?.data)
            setTotalData([{}]);
            setDis(false)
          })
        }
      }
      else
      {
        SetGetClick(false)
        setSearchFilterError(true)
        setSearchFilterErrorMsge("No search filter is checked")
        setTotalData([{}])
      }
      setDis(false)
    }

  return (
    <>
{<ApplicationBar></ApplicationBar>}

  {open1===true&&(<Dialog
      open={open1}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to exit this page?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All the changes will be lost if you decide to exit this page
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="Yes" onClick={(e)=>{e.preventDefault();handleClose();navigate("/Dashboard")}}>Yes</Button>
        <Button name="No"  onClick={(e)=>{e.preventDefault();handleClose()}} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>)}
    {rfqIDError===true&&(<Dialog
      open={rfqIDError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant='filled' severity={rfqIDErrorMsge==="No Records Found"?"warning":"error"}>{rfqIDErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {rfqIDErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {oppError===true&&(<Dialog
      open={oppError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant='filled' severity={oppErrorMsge==="No Records Found"?"warning":"error"}>{oppErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
          {oppErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {salesHeadError===true&&(<Dialog
      open={salesHeadError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant='filled' severity={salesHeadErrorMsge==="No Records Found"?"warning":"error"}>{salesHeadErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
        {salesHeadErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {dateError===true&&(<Dialog
      open={dateError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant='filled' severity={dateErrorMsge==="No Records Found"?"warning":"error"}>{dateErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
      {dateErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {searchFilterError===true&&(<Dialog
      open={searchFilterError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant='filled' severity="error">error</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
      {searchFilterErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    <Stack direction='column' spacing={1} >
      <Stack sx={{marginTop:"80px",marginLeft:"560px"}}>

        <Typography  sx={{fontSize:"30px",marginLeft:"0px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"430px"}}>Purchase Orders(POs)</Typography>
        </Stack>
        <Paper  autoComplete='off' elevation={3} sx={{":hover":{boxShadow:"10px 10px 20px #ccc"}}}>
      <Stack sx={{marginTop:'40px',marginLeft:'50px'}} direction='row' spacing={2}>
      <Stack direction='column' spacing={1}>
      <FormGroup>
    <FormControlLabel size="small" control={<Checkbox size="small" name="RFQIDSearch"  checked={getRFQIDChecked} onChange={(e)=>{handleRFQIDChange(e)}}/>} label="RFQID" />
     </FormGroup>
      <TextField required sx={{width:"230px"}} size="small" disabled={!getRFQIDChecked} InputProps={{startAdornment: (
          <InputAdornment position="start">
       
          </InputAdornment>
        ),}} label="RFQID" value={getRFQID} onChange={(e)=>{setgetRFQID(e.target.value)}}></TextField></Stack><Stack sx={{width:"50px"}}>

        </Stack>
         <Stack direction='column' spacing={1}>
        <FormGroup>
    <FormControlLabel size="small" control={<Checkbox size="small" name="Opportunity_Name_Search" checked={opportunityNameChecked} onChange={(e)=>{handleOpporNameChange(e)}}/>} label="Opportunity Name" />
     </FormGroup>
      <TextField  required sx={{width:"230px"}} size="small" disabled={!opportunityNameChecked} InputProps={{startAdornment: (
          <InputAdornment position="start">
           
          </InputAdornment>
        ),}} label="Opportunity Name" value={opportunityName} onChange={(e)=>{setOpportunityName(e.target.value)}} ></TextField></Stack><Stack sx={{width:"50px"}}></Stack>
        <Stack direction='column' spacing={1}>
        <FormGroup>
    <FormControlLabel size="small" control={<Checkbox size="small" name="Sales_Head_Search" checked={salesHeadNameChecked} onChange={(e)=>{handleSalesHeadEmailChange(e)}}/>} label="Sales Head Email" />
     </FormGroup>
     <FormControl>
                <InputLabel id="Sales-Head-id">
                   Sales Head Email ID
                </InputLabel>
                <Select
                label="Sales Head Email ID"
                  disabled={!salesHeadNameChecked}
                    labelId="Sales-Head-id"
                    value={salesHeadName}
                    size='small'
                    sx={{
                        width: "230px",
                    }}
                    onChange={(e)=>setSalesHeadName(e.target.value)}>
                  {usersData?.map((row,index)=>{return (<MenuItem value={row?.User_email}
                    >{row?.User_email}</MenuItem>)})}
                </Select>
            </FormControl>
        </Stack><Stack sx={{width:"50px"}}></Stack>
         <Stack direction='column' spacing={1}>
         <FormGroup>
    <FormControlLabel size="small" control={<Checkbox size="small" name="DateSearch" checked={getDateRangeChecked} onChange={(e)=>{handleDateRangeChange(e)}}/>} label="Created Date" />
     </FormGroup>
         <Stack direction='row' spacing={1}>
      <DatePicker disabled={!getDateRangeChecked} size="small" label="From"  maxDate={new Date()} required  value={DateValue.From_Date} onChange={(value)=>SetDateValue({...DateValue,From_Date:value})}  renderInput={(params)=><TextField size="small"  disabled={!getDateRangeChecked} {...params} />}>
      </DatePicker>
      <DatePicker  disabled={!getDateRangeChecked}  label="To" minDate={DateValue.From_Date} required  value={DateValue.To_Date} onChange={(value)=>SetDateValue({...DateValue,To_Date:value})}renderInput={(params)=><TextField disabled={!getDateRangeChecked}  variant="outlined" size="small" {...params} />}>
      </DatePicker></Stack></Stack>
      </Stack>
      <Stack sx={{marginLeft:"600px",marginTop:"30px"}}>       
        <FormControl size="small" sx={{width:"250px"}} >
        <InputLabel id="Quote_Status">Status</InputLabel>
        <Select size="small"
          labelId="Quote_Status"
          id="Quote_Status-id"
          value={stus}
          label="Quote Status"
          onChange={(e)=>{            
            setStus(e.target.value)
          }}
          sx={{width:"250px"}}>
          {/* <MenuItem size="small" value={"PENDING"}>PENDING</MenuItem> */}
          <MenuItem size="small" value={"WON"}>WON</MenuItem>
          {/* <MenuItem size="small" value={"LOST"}>LOST</MenuItem> */}
        </Select>
      </FormControl>
      </Stack>
      <Stack ><Button sx={{width:'250px',marginLeft:'600px',marginTop:"40px",marginBottom:"40px"}} variant="contained" color='success' disabled={dis} onClick={(e)=>Get_Data_Click(e)}>Get</Button></Stack>   </Paper>
      <Stack></Stack>
      {GetClick ===true &&(getDateRangeChecked===true ||opportunityNameChecked===true||salesHeadNameChecked===true||getRFQIDChecked===true)&& 
      (<TableContainer>
      <Table aria-label="POs_DateRange" sx={{border:1,borderColor:'black',width:"100%"}}>
        <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
          <TableRow>
          <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">SKUs</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Opp Name</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">RFQ ID</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Contract Vehicle</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Contracting Officer's Info</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Customer's Info</TableCell>
            <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Sales Head</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Status & Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <FinanceRow key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>)}
    </Stack>
    </>
  );
}
  export default POs_DateRange