import * as React from 'react';
import {useEffect,useState} from "react"
import {Box,Button,TextField,Paper, Stack,Typography,Link,Divider,Chip} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import PasswordIcon from '@mui/icons-material/Password';
import Login_Im from "../Images/Aspetto_Login.png"
import LogBack from "../Images/Login.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance}  from '../../axiosGlobalInstance';

const validUserName = new RegExp('^([A-z]{2,3})([0-9]{5,20})$');
const validPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!^&~*@#$%]).{5,15}$');

const Register_User=()=>
{
    const PD= new Date()
    const [conpass,setConPass]=useState("");
    const [matchPwd, setMatchPwd] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [userNameValid,setUserNameValid]=useState(false)
    const [passwordValid, setPasswordValid] = useState(false);
    const [phoneerrMsg, setPhoneErrMsg] = useState('');
    const [matchError,setMatchError]=useState("")
    const [dis,setDis]=useState(false)
    const [count,setCount]=useState(0)
    const[userCreatedAlert,setUserCreatedAlert]=useState("");
    const[userAbortedAlert,setUserAbortedAlert]=useState("");
    const [successDialog,setSuccessDialog]=useState(false)
    const [errorDialog,setErrorDialog]=useState(false)

    
    const [NewUser,setNewUser]=useState({
        User_FirstName :"",
        User_LastName : "",
        User_email :"",
        User_phone_number : "",
        Created_Date : String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()),
        UserName:"",
        DOB:"",
        UserPassword :""})
    const handleClose = (e) => {
          setErrorDialog(false)
          setSuccessDialog(false)
          };
    const handleClickShowPassword2 = () => {
            setConPass(!conpass);
          };
    const handleMouseDownPassword = (event) => {
            event.preventDefault();
          };
   const handleUserName=(e)=>{
        setNewUser({...NewUser,UserName:e.target.value})
        setUserNameValid(validUserName.test(e.target.value))
        setUserCreatedAlert('')
        setUserAbortedAlert('')
         }
   const handlePassword=(e)=>{
       setNewUser({...NewUser,UserPassword:e.target.value})
       setPasswordValid(validPassword.test(e.target.value))
  if(e.target.value!==matchPwd )
  {
    setValidMatch(false)
    setMatchError('Password Mismatch')
    setDis(true)
  }
  else
  {
     setValidMatch(true)
     setMatchError('')
     setDis(false)
  }
}

const handleConfirmPassword=(e)=>{
          setMatchPwd(e.target.value)
            if(e.target.value!==NewUser.UserPassword)
             {
     
              setValidMatch(false)
              setMatchError('Password Mismatch')
              setDis(true)
             }
             else
             {
                setValidMatch(true)
                setMatchError('')
                setDis(false)
             }
}

const handleUserEmail=(e)=>{
  setNewUser({...NewUser,User_email:String(e.target.value).trim().toUpperCase()})
            setUserCreatedAlert('')
            setUserAbortedAlert('')
            setDis(false)
  
}
const handleUserPhone=(e)=>{
  setNewUser({...NewUser,User_phone_number:e.target.value})
  setUserCreatedAlert('')
  setUserAbortedAlert('')
  setDis(false)
  
}

const handleSubmit=async(e)=>{
        setDis(true)
        e.preventDefault()
        await AxiosGlobalAPIInstance.post("/UserAuth/register",
        {...NewUser},
        ).then(
            (response)=>{
               setUserCreatedAlert(response?.data)
               setSuccessDialog(true)
               setUserAbortedAlert('')
               setNewUser({...NewUser,
                User_FirstName :"",
                User_LastName : "",
                User_email :"",
                User_phone_number : "",
                Created_Date : String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()),
                UserName:"",
                DOB:"",
                UserPassword :""})
                setDis(false)
                setConPass("")
                setMatchPwd("")
                setValidMatch(false)
              }).
          catch((err)=>{
          setUserAbortedAlert(err.response?.data)
          setErrorDialog(true)
          setUserCreatedAlert('')
                    }).finally(()=>{
                      setDis(false)
                            })
          }
    return (
        <Paper component="form" onSubmit={handleSubmit} autoComplete='off' elevation={0} sx={{width:"950px",height:'550px',marginTop:"60px",marginLeft:"480px",}}>
            {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        {userCreatedAlert && <Alert  severity='success' variant='filled'>SUCCESS</Alert>} 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {userCreatedAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)}} href="/">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {userAbortedAlert && <Alert  variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {userAbortedAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/UserRegistartion">OK</Button>
        </DialogActions>
      </Dialog>)}
      
        <Stack direction='column' spacing={3} sx={{marginTop:"10px",padding:4,width:"950px",marginLeft:"20px"}}>
        <Box component="img" src={Login_Im} sx={{height:"85px",width:"250px",marginLeft:"130px"}}>
              </Box>
              <Chip label="EMPLOYEE REGISTRATION" sx={{marginTop:"10px",fontSize:"15px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"490px",marginLeft:"150px"}}></Chip>
            <Stack direction='row' spacing={3}> 
            <TextField 
            type="text"  
            value={NewUser.User_FirstName} 
            onChange={(e)=>{setNewUser({...NewUser,User_FirstName:e.target.value})
            setUserCreatedAlert('')
          }} 
             size="small"  
             required 
             id="firstName" 
             autoComplete='off'
             label="First Name" 
             sx={{width:"230px",}} 
             InputProps={{startAdornment:(<InputAdornment position='start'>
            <PersonIcon />
            </InputAdornment>),}}>
            </TextField>
            <TextField 
            type="text"  
            value={NewUser.User_LastName} 
            onChange={(e)=>{setNewUser({...NewUser,User_LastName:e.target.value})
            setUserCreatedAlert('') }} 
            size="small" 
            required 
            id="Last Name" 
            autoComplete='off'
            label="Last Name" 
            sx={{width:"230px"}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
            </InputAdornment>),}}>
            </TextField>
            </Stack>
            <Stack direction='row' spacing={3}>
            <TextField 
            value={NewUser.User_email} 
            onChange={(e)=>{handleUserEmail(e)
            }} 
            size="small"
            required id="Email" 
            label="Email ID" 
            autoComplete='off'
            sx={{width:"230px"}} 
            type="email"
            InputProps={{startAdornment:(<InputAdornment position='start'>
            <EmailIcon />
            </InputAdornment>),}}>
            </TextField>
            <TextField 
            type="text"  
            value={NewUser.User_phone_number} 
            onChange={(e)=>{handleUserPhone(e)}}  
            size="small"  
            required 
            id="Phone" 
            autoComplete='off'
            label="Phone Number" 
            sx={{width:"230px",}} 
            InputProps={{startAdornment:(<InputAdornment 
            position='start'>
            <ContactPhoneIcon />
            </InputAdornment>),}}>
            </TextField>
            </Stack>
            <Stack direction='row' spacing={3}>
              <Stack direction='column' spacing={2}>
            <TextField 
            type="text"  
            value={NewUser.UserName} 
            onChange={(e)=>{
              handleUserName(e)
            }}  
            size="small"  
            required 
            id="UserName" 
            label={"Username "}
            autoComplete='off'
            sx={{width:"230px",}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
                <AccountCircleIcon></AccountCircleIcon>
            </InputAdornment>),}}>
            </TextField>
             {NewUser.UserName!==''&& (userNameValid ? <Alert severity='success' >{"Username is Valid"}</Alert>:
             <Alert sx={{width:"200px"}} severity='error'>{`Should start with 2-3 Letters from [A-z], Followed by numbers from [0-9]
             Number of characters must be between 7 to 20.
             Example: AS35226 or ASP3522`}</Alert>)}
             </Stack>
             <DatePicker label="Date Of Birth" sx={{width:"230px"}} value={NewUser.DOB} maxDate={new Date()} renderInput={(params)=><TextField size="small" required sx={{width:"230px"}} {...params}/>} onChange={(value)=>setNewUser({...NewUser,DOB:value})}>
        </DatePicker>
            
            </Stack>
            <Stack direction='row' spacing={3}>
              <Stack direction='column' spacing={2}>
            <TextField 
            type="password"
            value={NewUser.UserPassword} 
            onChange={(e)=>{handlePassword(e)}} 
            size="small" 
            required 
            id="Password" 
            label="Password" 
            autoComplete='off'
            sx={{width:"230px"}} 
            InputProps={{startAdornment:(<InputAdornment position='start'>
       <PasswordIcon></PasswordIcon>
        </InputAdornment>),}}>
            </TextField>
            {NewUser.UserPassword!==''&& (passwordValid ? <Alert severity='success' >{"Password is Valid"}</Alert>:
             <Alert sx={{width:"200px"}} severity='error'>{"Should contain atleast one (a-z), one (A-Z), one(0-9) and one special character from (!^&~*@#$%) and total length should be between 5 to 15 "}</Alert>)}
            </Stack>
            <Stack direction="column" spacing={2}>
            <TextField 
            type={conpass?"text":"password" } 
            value={matchPwd} 
            onChange={(e)=>{
              handleConfirmPassword(e)
             }}
            size="small" 
            required 
            id="matchPwd" 
            label="Confirm Password" 
            autoComplete='off'
            sx={{width:"230px"}} 
            InputProps={{endAdornment:(<InputAdornment position='end'>
            <IconButton onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword}>{conpass?<VisibilityIcon></VisibilityIcon>:<VisibilityOffIcon></VisibilityOffIcon>}</IconButton>
        </InputAdornment>),}}>
            </TextField>
            </Stack>
            {NewUser.UserPassword!=='' && matchPwd!==''&& (validMatch ?<Alert sx={{width:"200px",height:"40px"}} severity='success'>Passwords matched</Alert>:<Alert sx={{width:"200px",height:"40px"}} severity="error">{matchError}</Alert>)}</Stack>
            <Stack  direction='row' spacing={3}>
             <Button variant='contained' type="submit" disabled={dis} color="success" sx={{width:"230px",height:"30px",borderRadius:3}} size='small'>Sign Up</Button>
             <Stack direction='column' spacing={1}>
             <Stack  direction='row' spacing={0}>
        <Typography type="body"  sx={{padding:"0px", fontSize:"15px",fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Already an User?</Typography>
        <Link href="/" underline="always" target="_blank" sx={{paddingLeft:"5px",fontSize:"15px",paddingTop:'2px'}}>Sign In</Link>
        </Stack>
        <Stack  direction='row' spacing={0} sx={{}}>
        <Typography type="body"  sx={{padding:"0px", fontSize:"15px", fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Forgot Password?</Typography>
        <Link href="#" underline="always" sx={{paddingLeft:"5px",fontSize:"15px",paddingTop:'2px'}}>Reset Your Password</Link>
        </Stack>
        </Stack>
        </Stack>
        </Stack>
    </Paper>
    )

}
export default Register_User