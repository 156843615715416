import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
const authSlice = createSlice({
    name: 'auth',
    initialState: { user:null,token: null,sessionID:null,exp:0,IsLoggedIn:false },
    reducers: {
        setCredentials: (state, action) => {
            const accessToken= action.payload
            state.token = accessToken
            state.user=jwtDecode(accessToken).userInfo
            
        },
        
        logOut: (state, action) => {
            state.token = null
            state.user=null

        },

       setSessionStart:(state,action)=>{
        const {exp}=jwtDecode(state.token)
        state.exp=exp
       }
    }
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token

export const selectCurrentUser = (state) => state.auth.user

export const selectCurrentUserSessionExpiration = (state) => state.auth.exp

export const selectCurrentUserSessionID = (state) => state.auth.sessionID

export const selectCurrentUserIsLoggedIn = (state) => state.auth.IsLoggedIn