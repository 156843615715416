import {React} from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        fontSize:9
    },
    Sales_Head: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'Left',
        paddingRight: 8,
        Heigth:'60px',
        fontSize:9
    },
    Shipping_Method: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    Shipping_Terms:{
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    Estimated_Delivery:{
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
    Payment_Terms: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
        fontSize:9
    },
});

const ShippingTableRow = ({ Shipping_Details }) => {
   
    return(<View style={styles.row} >
            <Text style={styles.Sales_Head}>{Shipping_Details.Sales_Head_Info.Name+", "+
             Shipping_Details.Sales_Head_Info.Email+", "+Shipping_Details.Sales_Head_Info.Phone}</Text>
            <Text style={styles.Shipping_Method}>{Shipping_Details.Shipping_Method}</Text>
            <Text style={styles.Shipping_Terms}>{Shipping_Details.Shipping_Terms}</Text>
            <Text style={styles.Estimated_Delivery}>{Shipping_Details.Estimated_Delivery}</Text>
            <Text style={styles.Payment_Terms}>{Shipping_Details.Payment_Terms}</Text>
        </View>
    )
};

export default ShippingTableRow;