import * as React from 'react';
import {useState,useContext} from "react"
import {Box,Button,TextField,Paper, Stack,Typography,Link,Divider,Chip} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IconButton } from '@mui/material';
import Login_Im from "../Images/Aspetto_Login.png";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {blueGrey } from '@mui/material/colors'
import { createTheme} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import {useLocation,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {AxiosGlobalAPIInstance} from '../../axiosGlobalInstance'
import { selectCurrentToken } from './authSlice';
import { useSelector } from 'react-redux';
import { set } from 'date-fns';

const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
function Login_Form()
{
    const [user,setUser]=useState("");
    const [pass,setPass]=useState({password:"",showPassword:false});
    const [err,setErr]=useState("");
    const [errMsge,setErrMsge]=useState("");
    const [errorDialog,setErrorDialog]=useState(false)
    const [successDialogDecision,setSuccessDialogDecision]=useState(false)
    const location =useLocation();
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const token=useSelector(selectCurrentToken)

    

 const handleClose = (e) => {
      e.preventDefault();
      setErrorDialog(false)
      setErrMsge("")
    }

const handleClickShowPassword = (event) => {
        event.preventDefault();
        setPass({ ...pass, showPassword: !pass.showPassword });
      };

const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      
const handleOtherSessions=(e)=>{
  setSuccessDialogDecision(true)
}

   const handleSubmit=async(e)=>
   {
    e.preventDefault();
    const UserPassword=pass.password;
    const UserName=user
     
      await AxiosGlobalAPIInstance.post('/UserAuth',{ UserName,UserPassword}).then(async(response)=>{
          const {accessToken}=response?.data
          dispatch(setCredentials(accessToken))
          navigate('/Dashboard',{ replace: false })
      }).catch((error)=>{
         if(error?.response.status===404)
         {
          setErrorDialog(true)
          setErrMsge("Page Not Found")
         }
        else
        {
          setErr(error?.response.data);
          setErrorDialog(false)
          setErrMsge("")
        }
          

      })

  } 

    return(
        <> 
        {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle sx={{width:"250px"}} id="alert-dialog-title">
        <Alert variant='filled' severity='error'>error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {errMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          handleClose(e)
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
        <Paper component="form" onSubmit={(e)=>handleSubmit(e)} autoComplete='off' elevation={2} sx={{width:"650px",height:'370px',marginLeft:"450px",marginTop:"0px",
        ":hover":{
            boxShadow:"10px 10px 20px #ccc"
        }}}>
            <Stack  direction='row' spacing={3} sx={{marginTop:"130px"}}>
                <Stack>
                  <Box component="img" src={Login_Im} sx={{height:"80px",width:"240px",marginLeft:"40px",marginTop:"120px"}}>
                  </Box>
                  <Chip label="EMPLOYEE LOGIN" sx={{marginTop:"10px",fontSize:"20px",marginLeft:"50px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"230px"}}></Chip>
                </Stack>
               <Divider type=""></Divider>
            <Stack direction='column' spacing={3} sx={{marginLeft:"150px",}} >
          
            <Stack sx={{height:"25px",marginTop:"15px"}}>{err && <Alert sx={{width:"200px"}} severity='error'>{err}</Alert>}</Stack>
            <p></p>
                <TextField type="text"  
                value={user} 
                onChange={(e)=>{setUser(e.target.value)
                setErr('')
                }}  
                size="small"  
                required 
                id="UserName" 
                label="UserName" 
                sx={{width:"230px",}} 
                InputProps={{startAdornment:(<InputAdornment position='start'>
                    <AccountCircleIcon></AccountCircleIcon>
                </InputAdornment>),}}>
                </TextField>
                <TextField 
                type={pass.showPassword ? "text" : "password"}
                value={pass.password} 
                onChange={(e)=>{setPass({...pass,password:e.target.value})
                setErr('')
              }} 
                size="small" 
                required 
                id="Password" 
                label="Password" 
                sx={{width:"230px"}} 
                InputProps={{endAdornment:(<InputAdornment position='end'>
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{pass.showPassword ?<VisibilityIcon></VisibilityIcon>:<VisibilityOffIcon></VisibilityOffIcon>}</IconButton>
                </InputAdornment>),}}>
                </TextField>
                 <Button variant='contained' type="submit" color="success" sx={{width:"230px",}} size='small'>LOGIn</Button>
            <Stack  direction='column' spacing={1}>
            <Stack  direction='row' spacing={0} sx={{}}>
            <Typography type="body"  sx={{padding:"0px", fontSize:"15px",fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Need an Account?</Typography>
            <Link href="#" underline="always" sx={{paddingLeft:"5px",fontSize:"15px",paddingTop:'2px'}}>Sign Up</Link>
            </Stack>
            <Stack  direction='row' spacing={0} sx={{marginLeft:"150px"}}>
            <Typography type="body"  sx={{padding:"0px", fontSize:"15px", fontFamily:'Verdana (sans-serif)',marginLeft:"5px"}}>Forgot Password?</Typography>
            <Link href="#" underline="always" sx={{paddingLeft:"5px",fontSize:"15px",paddingTop:'2px'}}>Reset Your Password</Link>
            </Stack>
            </Stack>
            </Stack>
            </Stack>
        </Paper>
        </>
    )
}
export default Login_Form