import React,{Fragment} from 'react';
import { Text, View, StyleSheet} from '@react-pdf/renderer';
const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop:"350px",
        justifyContent: 'center',
    },
    label: {
        width: 90,
        fontSize:"10px",
    }
});

const InvoiceThankYouMsg = () => (
    <Fragment>
    <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>
            *************Thank You*****************
        </Text>
    </View >
</Fragment>
        	
);

export default InvoiceThankYouMsg;