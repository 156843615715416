import React from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState,useEffect} from "react";
import {Button,Avatar,Tooltip} from '@mui/material';
import {blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import PdfDocument from "../../../PDF/Main";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { orange,indigo } from '@mui/material/colors';

const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[500],
      },
    },
  });
  
  const color1=blueGrey[500]
  const color2=blueGrey[50]
  
function FinanceRow(props) {
    const PD= new Date()
    const { row} = props;
    const [open, setOpen] = React.useState(false);
    const[clicked,setClicked]=useState(false);
   
  {/*---------------------------------------------Defining variables for PDF data---------------------------------------------------------------------*/}
  const [InvoiceData,setInvoiceData]=useState({ 
    invoice_no:"",
    KO_name:"",
    KO_email:"",
    KO_phone: "",
    KO_address:"",
    trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
    due_date: "",
    Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax:row.Tax,
    Shipping_Cost:row.Shipping_Cost,
    Total_Cost:row.Actual_Total_Cost_After_Margin,
    Shipping_Details:{
      Organization_Name:"",
      KO_name:"",
      Sales_Head_Info:{},
      Customer_Name:"",
      Customer_Email:"",
      Customer_Phone:"",
      Customer_Address:"",
      Shipping_Method:"",
      Shipping_Terms:"",
      Estimated_Delivery:"",
      Payment_Terms:"",
      Notes:""}})
  const HandlePDFClick=(e)=>{
    setInvoiceData({...InvoiceData,
      invoice_num:String(row.Invoice_No),
      KO_name: row.KO_name,
      KO_email:row.KO_email,
      KO_phone: row.KO_phone,
      KO_address:row.KO_address,
      trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
      due_date: row.Due_date,
      Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: row.Purchase_Orders,
      Tax:row.Tax,
      Shipping_Cost:row.Shipping_Cost,
      Total_Cost:row.Actual_Total_Cost_After_Margin,
      Shipping_Details:{
        Organization_Name:row.Organization_Name,
        KO_name:row.KO_name,
        Sales_Head_Info:{Name:row.Sales_Head_Name,Email:row.Sales_Head_Email,Phone:row.Sales_Head_Ph},
        Customer_Name:row.Customer_Name,
        Customer_Email:row.Customer_Email,
        Customer_Phone:row.Customer_Phone,
        Customer_Address:row.Customer_Address,
        Shipping_Method:row.Shipping_Method,
        Shipping_Terms:row.Shipping_Terms,
        Estimated_Delivery:row.Estimated_Delivery,
        Payment_Terms:row.Payment_Terms,
        Notes:row.Notes}
    })
    setClicked(true)
  }
  
    return (
      <React.Fragment>
        <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {e.preventDefault();setOpen(!open)}}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}} align="center" scope="row">{row.Opportunity_Name}
          </TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.RFQ_ID}</TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Contract_Vehicle}</TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.KO_name}<br></br><b>Organization:</b>{row.Organization_Name}<br></br><b>Address:</b>{row.KO_address}<br></br>
          <b>Email:</b>{row.KO_email}<br></br><b>Phone:</b>{row.KO_phone}</Box></TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.Customer_Name}<br></br><b>Address:</b>{row.Customer_Address}<br></br>
          <b>Email:</b>{row.Customer_Email}<br></br><b>Phone:</b>{row.Customer_Phone}</Box></TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="Left">{row.Sales_Head_Name+", "+row.Sales_Head_Email+", "+row.Sales_Head_Ph}<br></br>
          <b>Created Date :</b>{String(row.Created_Date).split('T')[0]}<br></br>
          <b>Last Updated Date :</b>{String(row.Last_Updated_Date).split('T')[0]}<br/>
          <b>Updated By:</b><br/>{String(row.Updated_By)}</TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">
          {row.RFQ_Status==="PENDING"&& <Button color="warning" type="text" size="small" variant="contained" sx={{height:"25px",fontSize:"10px",}}>{row.RFQ_Status}</Button>}
          {row.RFQ_Status==="WON"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small" color="success" type="text" variant="contained">{row.RFQ_Status}</Button>}
          {row.RFQ_Status==="LOST"&& <Button sx={{width:"50px",fontSize:"10px"}} size="small"  color="error" type="text" variant="contained">{row.RFQ_Status}</Button>}
          <br></br><br></br>
          <Typography variant='h7' sx={{width:"50px",fontSize:"14px"}}><b>Revision:</b> {row.Version_No}</Typography><br></br>
          <IconButton color="secondary" type="text" size="small" variant="contained" sx={{marginTop:"10px",height:"35px",fontSize:"10px"}}><Avatar variant='rounded' sx={{backgroundColor:[orange[900]]}}><PictureAsPdfIcon onClick={(e)=>{
            e.preventDefault();
            HandlePDFClick();}}></PictureAsPdfIcon></Avatar></IconButton>
          {clicked===true &&(<PDFDownloadLink onClick={HandlePDFClick} component="button" color="secondary"
              document={<PdfDocument invoicedata={InvoiceData}/>}
              fileName={row.Opportunity_Name+" "+row.RFQ_ID}>
              {({ blob, url, loading, error }) =>
                loading ? "Loading..." : "Download"
              }
            </PDFDownloadLink>)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1,}}>
                {/* <Typography variant="h6">
                  SKU INFO
                </Typography> */}
                <Table aria-label="purchases" sx={{border:1,borderColor:'text.main'}}>
                  <TableHead sx={{backgroundColor:theme.palette.secondary.main,}}>
                    <TableRow>
                    <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}}>Vendor/Partner Name</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}}>SKU ID</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">SKU Description</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">QTY</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">UM</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Aspetto's Unit Cost($)</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Margin(%)</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Customer's Unit Cost($)</TableCell>
                      <TableCell sx={{color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Total SKU Cost($)</TableCell>
                  
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row["Purchase_Orders"]?.map((Purchase_OrderROW,index) => (
                      <TableRow key={index}>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Vendor_Partner_name}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.SKU_ID}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.SKU_Description}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.QTY}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Unit_Of_Measure}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Aspetto_Unit_Cost}</TableCell>
                        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{Purchase_OrderROW.Margin_Percent}</TableCell>
                        <TableCell  sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Customer_Unit_Cost}</TableCell>
                        <TableCell  sx={{border:1,borderColor:'text.primary'}} align="center">{Purchase_OrderROW.Total_SKU_Cost}</TableCell>
                
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    
                      <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">Shipping Cost</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Shipping_Cost}</TableCell>
                    <TableCell></TableCell>
                   
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                  
                      <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">Tax</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Tax}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                  
                      <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">TOTAL</TableCell>
                    <TableCell sx={{border:1,borderColor:'text.primary'}} align="center">{row.Actual_Total_Cost_After_Margin}</TableCell>
                    <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  export default FinanceRow