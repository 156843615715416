import {React} from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './Table-Header';
import InvoiceTableRow from './Table-Row';
import InvoiceTableFooter from './TableFooter';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
        borderWidth: 1,
        borderColor: 'black',
    },
});

const InvoiceItemsTable = ({ invoice }) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow invoice={invoice} />
        <InvoiceTableFooter invoice={invoice} />
    </View>
);

export default InvoiceItemsTable;