import {React} from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { style } from '@mui/system';
const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'black',
        color: 'white',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    Sales_Head: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:9
    },
    Shipping_Method: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:9
    },
    Shipping_Terms: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:9
    },
    Estimated_Delivery: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize:9
    },
    Payment_Terms: {
        width: '15%',
        fontSize:9
    },
});

const ShippingHeader = () => (
    <View style={styles.container}>
        <Text style={styles.Sales_Head}>Sales Head</Text>
        <Text style={styles.Shipping_Method}>Shipping Method</Text>
        <Text style={styles.Shipping_Terms}>Shipping Terms</Text>
        <Text style={styles.Estimated_Delivery}>Estimated Delivery</Text>
        <Text style={styles.Payment_Terms}>Payment Terms</Text>
    </View>
);

export default ShippingHeader;