import * as React from 'react';
import Layout from './Pages/LayoutComponents/Layout';
import {Route,Routes} from 'react-router-dom'
import { createTheme } from '@mui/system';
import {LocalizationProvider} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import HomePage from './Pages/Home/Home';

import Login_Form from './Pages/Login_Registration/login';
import Register_User from './Pages/Login_Registration/register';
import ProfilePage from '../src/Pages/User/Profile'

import RFQ_CLIENT_INPUT from './Pages/RFQ/rfqCreate';
import RFQ_CLIENT_INPUT_UPDATE from "./Pages/RFQ/rfqUpdate"
import RFQDrafts from './Pages/RFQ/RFQDrafts/rfqdrafts';
import RFQs_Search_Update from './Pages/RFQ/RFQsSearch/rfqsSearch';
import POs_Search from './Pages/RFQ/RFQsSearch/rfqsPOsSearch';

import RFI_CLIENT_INPUT from './Pages/RFI/rfiCreate';
import RFIs_Search_Update from './Pages/RFI/rfisSearch';
import RFI_Client_Input_Update from './Pages/RFI/rfiUpdate';
import DashLayout from './Pages/LayoutComponents/DashLayout';

import RequiredAuth from './Pages/ProtectedRoute/RequiredAuth';
function App() {
const theme=createTheme({
palette:{}

})

  return (
    
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Routes>
        <Route path="/" element={<Layout/>}>
        <Route index element={<Login_Form/>}/>
        <Route exact path="UserRegistartion" element={<Register_User/>}/>
        <Route element={<RequiredAuth/>}>
        <Route path="Dashboard" element ={<DashLayout/>}>
        <Route index exact element={<HomePage/>}/>
        <Route path="Profile" exact element={<ProfilePage/>}/>
        <Route path="CreateRFQ" exact element={<RFQ_CLIENT_INPUT />}/>
        <Route path="CreateRFI" exact element={<RFI_CLIENT_INPUT />}/>
        <Route path="POsSearch" exact element={<POs_Search/>}/>
        <Route path="RFQsSearch" exact element={<RFQs_Search_Update/>}/>
        <Route path="RFIsSearch" exact element={<RFIs_Search_Update/>}/>
        <Route path="UpdateRFQ" exact element={<RFQ_CLIENT_INPUT_UPDATE/>}/>
        <Route path="UpdateRFI" exact element={<RFI_Client_Input_Update/>}/>
        <Route path="RFQDrafts" exact element={<RFQDrafts/>}/>
        </Route>
        </Route>
        </Route>
      </Routes>
  
    </LocalizationProvider>
    );
   
}
export default App;
