import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 10,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});
const InvoiceTableFooter = ({ invoice}) => {
    let cal=0;
    const total = invoice.items?.map(item => 
        {   cal=cal+item.QTY*item.Customer_Unit_Cost;
        })
    const Shipping_Cost=invoice.Shipping_Cost
    const Tax=invoice.Tax
return(
<View>
    <View style={styles.row}> 
    <Text style={styles.description}>Shipping Cost</Text>
    <Text style={styles.total}>{parseFloat(Shipping_Cost).toFixed(2)}</Text>
    </View>
    <View  style={styles.row}>
    <Text style={styles.description}>Tax</Text>
    <Text style={styles.total}>{parseFloat(Tax).toFixed(2)}</Text>
    </View>
    <View  style={styles.row}>
    <Text style={styles.description}>TOTAL</Text>
    <Text style={styles.total}>{'$'+Number.parseFloat(invoice.Total_Cost).toFixed(2)}</Text>
    </View>
</View>)
};


export default InvoiceTableFooter;