import {Box,Button,TextField,Paper, Stack,Typography,Grid,ButtonBase,IconButton, Avatar,Tooltip} from '@mui/material';
import { useState} from "react";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import ApplicationBar from "../../AppBar/ApplicationBar"
import UserAuth from '../../ProtectedRoute/userAuth';
import { styled } from '@mui/material/styles';
import ModeEdit from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { orange,indigo,red,green } from '@mui/material/colors';
import { createSearchParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../../Login_Registration/authSlice';
import Badge from '@mui/material/Badge';
import axios from 'axios';
import AxiosFunction from '../../../axiosCustomInstance';
import { useDispatch } from 'react-redux';
import { selectCurrentUserSessionExpiration } from '../../Login_Registration/authSlice';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: blueGrey[900],
      },
      secondary: {
        main: blueGrey[300],
      },
    },
  });
const RFQDrafts=()=>{
const {UserName,UserEmail}=UserAuth()
const navigate=useNavigate()
const PD= new Date()
const today=new Intl.DateTimeFormat('en-US',{dateStyle:'medium'}).format(PD)
const [errorAndSuccessDialog,setErrorAndSuccessDialog]=useState(false)
const [successDialogMsge,setSuccessDialogMsge]=useState("")
const [errorDialogMsge,setErrorDialogMsge]=useState("")
const [errorStatus,setErrorStatus]=useState(false)
const [areYouSureDialog,setAreYouSureDialog]=useState(false)
const [areYouSureDialogDecision,setAreYouSureDialogDecision]=useState(false)
const [tranferQuoteDialog,setTranferQuoteDialog]=useState(false)
const [TransferToEmaill,setTransferToEmail]=useState("")
const [TransferToComments,setTransferToComments]=useState("")
const [rfqID,setRFQID]=useState("")
const [deleteWorkingSalesHead,setDeleteSalesHeadEmail]=useState("")
const dispatch=useDispatch()
const AxiosAPIInstance=AxiosFunction()


    
const [Form_Value,Set_Form_Value]=useState([{Opportunity_Name:'',RFQ_ID:'',Invoice_No:'',Contract_Vehicle:'GSA',KO_name:'',KO_email:'',
KO_phone:'',KO_address:'',Published_date:null,Due_date:null,Brief:'',Organization_Name:'',
Customer_Name:'',Customer_Email:'',Customer_Phone:'',Customer_Address:'',Shipping_Method:'',Sales_Head_Name:'',Sales_Head_Email:UserEmail||"sales@aspettoinc.com",Sales_Head_Ph:"",
Shipping_Terms:'FOB Origin',Estimated_Delivery:'',Payment_Terms:'NET30',Notes:'',Shipping_Cost:Number(parseFloat(0).toFixed(2)),
Tax:Number(parseFloat(0).toFixed(2)),
Created_Date:today,
Last_Updated_Date:today,RFQ_Status:'PENDING',
Updated_By:'TBD',Version_No:parseInt(0),Updating_Comments:'RFQ Created under PENDING Status'
}])
let [usersData,setUsersData]=useState([{User_email:""}])
const exp=useSelector(selectCurrentUserSessionExpiration)

React.useEffect(()=>{
  const fetchDraftsData=async()=>{
      await AxiosAPIInstance.get("/RFQDrafts/GetAllDrafts",
        {params:{Working_Sales_Head:UserEmail}},).then(async(response)=>{
         const FormData=[...response?.data]
         Set_Form_Value(FormData)
      }).catch((error)=>{
          setErrorAndSuccessDialog(true)
          setErrorDialogMsge(error?.response.data)
          setErrorStatus(true)
      })
  }
  fetchDraftsData();
},[errorAndSuccessDialog]);

React.useEffect(()=>{

  const fetchUsersData=async()=>{
        await AxiosAPIInstance.get("/UserAuth/users").then((result)=>{
          const users=[...result?.data]
          setUsersData(users)
          setErrorAndSuccessDialog(false)
          setErrorStatus(false)
          setErrorDialogMsge("")
        }).catch((error)=>{
          setErrorAndSuccessDialog(true)
          setErrorDialogMsge(error?.response.data)
          setErrorStatus(true)
      })
  }
  fetchUsersData();

},[]);

const handleErrorSuccesAreYouSureClose=(e)=>{
  e.preventDefault()
  if (errorStatus===true)
  {
    setErrorStatus(false)
    setAreYouSureDialog(false)
    setAreYouSureDialogDecision(false)
    setErrorAndSuccessDialog(false)
    setSuccessDialogMsge("")
    setErrorDialogMsge("")
  }
else
{
  setAreYouSureDialog(false)
  setAreYouSureDialogDecision(false)
  setErrorAndSuccessDialog(false)
  setSuccessDialogMsge("")
  setErrorDialogMsge("")
  setTransferToEmail("")
  setRFQID("");
  setDeleteSalesHeadEmail("")
  setTransferToComments("")
}
}

const handleTransfer=async(e,RFQrow)=>{
  e.preventDefault()
  setRFQID(RFQrow?.RFQ_ID)
  setTranferQuoteDialog(true)
  setTransferToEmail("");setTransferToComments("")

}

const handleDeleteDialog=async(e,RFQrow)=>{
  e.preventDefault()
  setErrorStatus(false)
  setAreYouSureDialog(true)
  setRFQID(RFQrow?.RFQ_ID)
  setDeleteSalesHeadEmail(RFQrow?.Working_Sales_Head)
}

const handleEdit=async(e,RFQrow)=>{
    try
    {
    e.preventDefault();
    navigate(
        {
            pathname:"/Dashboard/CreateRFQ",
            search:createSearchParams({RID:RFQrow?.RFQ_ID,SHEm:RFQrow?.Working_Sales_Head}).toString()
        }
    )
    }
    catch(error)
    { 
        setErrorAndSuccessDialog(true)
        setErrorStatus(true)
        setErrorDialogMsge("Cannot Complete your request ")
        
    
    }
}
const handleDelete=async(e)=>{
    e.preventDefault()
    setAreYouSureDialogDecision(true);
    await AxiosAPIInstance.delete("/RFQDrafts/DeleteRFQDrafts",{params:{RFQ_ID:rfqID,Working_Sales_Head:deleteWorkingSalesHead}}).then((response)=>{ 
      setAreYouSureDialog(false)
      setAreYouSureDialogDecision(false)
      setErrorAndSuccessDialog(true)
      setErrorStatus(false)
      setSuccessDialogMsge(response?.data)
    }).catch((error)=>{
        setErrorAndSuccessDialog(true)
        setErrorStatus(true)
        setErrorDialogMsge("Some Error Occurred While Deleting the Draft")
    })
}

const handleTransferTo=async(e,RFQrow)=>{
  e.preventDefault()
  const Body={RFQ_ID:rfqID,TransferToEmail:TransferToEmaill}
  await AxiosAPIInstance.post("/RFQDrafts/TransferDrafts",Body).then((response)=>{
    setTranferQuoteDialog(false)
    setErrorAndSuccessDialog(true)
    setErrorStatus(false)
    setSuccessDialogMsge(response?.data)
  }).catch((error)=>{
      setErrorAndSuccessDialog(true)
      setErrorStatus(true)
      setErrorDialogMsge(error?.response.data)
  })

}
    return(
        <>
     {<ApplicationBar></ApplicationBar>}
     {errorAndSuccessDialog===true&&(<Dialog
        open={errorAndSuccessDialog}
        onClose={handleErrorSuccesAreYouSureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
        {!errorStatus ? <Alert variant='filled' severity='success'>success</Alert>:<Alert variant='filled' severity='error'>error</Alert>}
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          {!errorStatus?successDialogMsge:errorDialogMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
         handleErrorSuccesAreYouSureClose(e)}}>OK</Button>
        </DialogActions>
      </Dialog>)}
      {<ApplicationBar></ApplicationBar>}
     {areYouSureDialog===true&&(<Dialog
        open={areYouSureDialog}
        onClose={handleErrorSuccesAreYouSureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='warning'>warning</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          Once deleted, it is deleted Permenantly. Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="YES" onClick={(e)=>{handleDelete(e)}}>YES</Button>
          <Button name="NO" onClick={(e)=>{e.preventDefault();setAreYouSureDialog(false);setAreYouSureDialogDecision(false);navigate("/Dashboard/RFQDrafts")}}>
           NO
          </Button>
        </DialogActions>
      </Dialog>)}

      <Grid container flexGrow="inherit" direction="row" spacing={2}>
        <Grid  container flexGrow="inherit" sx={{marginTop:"100px",marginLeft:"80px",marginRight:"80px"}} spacing={2}>
      {Form_Value?.map((RFQrow,index)=>{
    return(
      <>
      <Grid key={RFQrow} item xs={10} sm={4} md={5} sx={{marginLeft:"50px",marginRight:"50px"}}>
        <Paper key={RFQrow} component="form"  onSubmit={(e)=>{handleEdit(e,RFQrow)}} p={2} sx={{ backgroundColor:blueGrey[200], ":hover":{boxShadow:"10px 10px 20px #ccc"}}}>
        
        {tranferQuoteDialog===true&&(<Dialog
        key={RFQrow}
        open={tranferQuoteDialog}
        onClose={handleErrorSuccesAreYouSureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title" sx={{width:"450px"}}>
        <Alert variant='filled' severity='info'>Please Enter Tranfer Details:</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"450px",height:"280px"}}>
          <DialogContentText id="alert-dialog-description">
          <Stack sx={{textAlign:"center",alignItems:"center"}}>
          <b>RFQ ID</b><TextField  size='small' value={rfqID}  disabled  sx={{width:"300px"}}></TextField>
          <br/>
          <FormControl>
                <InputLabel id="Transfer-to-email-id">
                   Select User Email ID
                </InputLabel>
                <Select
                    labelId="Transfer-to-email-id"
                    value={TransferToEmaill}
                    variant='standard'
                    style={{
                        width: 300,
                    }}
                    onChange={(e) => {
                      setTransferToEmail(e.target.value)
                    }}
                >
                  {usersData?.map((row,index)=>{return (<MenuItem value={row?.User_email}
                    >{row?.User_email}</MenuItem>)})}
                </Select>
            </FormControl>
            <br/>
          <b>Comments: </b><TextField  multiline maxRows={10} minRows={3} size='small' value={TransferToComments} onChange={(e)=>{setTransferToComments(e.target.value)}} sx={{width:"300px"}}></TextField>
          </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
   
              <IconButton  size="small" type="submit" variant="contained"  onClick={(e)=>{handleTransferTo(e,RFQrow)}}>
              <Avatar sx={{ backgroundColor:[green[500]] ,":hover":{boxShadow:"10px 10px 20px #ccc"}}} variant='rounded'><SendIcon/></Avatar>
              </IconButton>
              
         <IconButton name="Cancel" variant='contained' color='warning'  onClick={(e)=>{setTranferQuoteDialog(false);setTransferToEmail("");setTransferToComments("")}}> 
         <Avatar variant="rounded" sx={{ backgroundColor:[orange[900]] ,":hover":{boxShadow:"10px 10px 20px #ccc"}}}><DisabledByDefaultIcon/></Avatar> </IconButton>
        </DialogActions>
      </Dialog>)}
        <Typography  gutterBottom variant="h5" component="div" sx={{textAlign:'center',padding:"20px"}}>
        {RFQrow.Opportunity_Name}
        </Typography>
        <Typography  variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>RFQ ID:</b> {RFQrow.RFQ_ID}
        </Typography>
        
        <Typography  size="small"  variant='body2' sx={{textAlign:'center',marginTop:"20px"}}>
        <b>ACTIONS</b>
        </Typography>
         <Stack  sx={{marginLeft:"190px",marginTop:"2px"}} direction="row" spacing={2} >
              <Tooltip title="Edit">
              <IconButton size="small" variant="contained" type="submit">
              <Avatar sx={{ backgroundColor:[indigo[500]],":hover":{boxShadow:"10px 10px 20px #ccc"}}} variant='rounded'><ModeEdit/></Avatar>
              </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
              <IconButton  size="small" type="submit" variant="contained" onClick={(e)=>{ handleDeleteDialog(e,RFQrow)}}>
              <Avatar sx={{ backgroundColor:[orange[900]],":hover":{boxShadow:"10px 10px 20px #ccc"} }} variant='rounded'><DeleteOutlineIcon/></Avatar>
              </IconButton>
              </Tooltip>
              <Tooltip title="Tranfer Quote to your colleague's bin">
              <IconButton  size="small" type="submit" variant="contained"  onClick={(e)=>{ handleTransfer(e,RFQrow)}}>
              <Avatar sx={{ backgroundColor:[green[500]] ,":hover":{boxShadow:"10px 10px 20px #ccc"}}} variant='rounded'><SendIcon/></Avatar>
              </IconButton>
              </Tooltip>
        </Stack>
        {/* <Button variant='contained' size="small" sx={{marginLeft:"190px",textAlign:'center',":hover":{boxShadow:"10px 10px 20px #ccc"}, alignItems:"center",marginTop:"20px"}}>
        GET DRAFT HISTORY</Button> */}
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Created By:</b> <br/>{String(RFQrow?.Sales_Head_Email)}
        </Typography>

        <Stack  sx={{textAlign:"center",marginTop:"2px",padding:"20px"}} direction="column" spacing={1} >
        <Typography  variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Created Date:</b> {String(RFQrow.Created_Date).split("T")[0]}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Updated By:</b> <br/>{String(RFQrow?.Updated_By)}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Updated Date:</b> {String(RFQrow.Last_Updated_Date).split("T")[0]}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{textAlign:'center'}}>
        <b>Status History:</b> {String(RFQrow?.Transfer_History)}
        </Typography>
    </Stack> 
        </Paper>
        </Grid>
        </>
      )})}
      </Grid>
</Grid>

       </>
    );
}
export default RFQDrafts