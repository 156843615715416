import {Box,Button,TextField,Stack,Chip, IconButton, Avatar, Tooltip} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { DatePicker } from "@mui/x-date-pickers";
import * as React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { blueGrey, green, orange } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import ApartmentIcon from '@mui/icons-material/Apartment';
import ModeEdit from '@mui/icons-material/ModeEdit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSearchParams } from 'react-router-dom';
import { selectCurrentUser } from '../Login_Registration/authSlice';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from "../../axiosCustomInstance"
import { selectCurrentToken} from '../Login_Registration/authSlice';
import { useSelector } from 'react-redux';

const ProfilePage=()=>{
    const PD= new Date()
    const theme = createTheme({
        palette: {
          primary: {
            main: blueGrey[900],
          },
          secondary: {
            main: blueGrey[500],
          },
        },
      });
    const User=useSelector(selectCurrentUser)
    const [editClicked,setEditClicked]=useState(false)
    const[Form_Value,Set_Form_Value]=useState({ FirstName:"",LastName:"", UserName:"",DOB:"",AddressLine1:"",AddressLine2:"",City:"",State:"",Country:"",Zip:"",phone_number:"",email:"",Created_Date:""})
    const[errorMsge,setErrorMsge]=useState("")
    const[successMsge,setSuccessMsge]=useState("")
    const[errorSuccessDialog,setErrorSuccessDialog]=useState(false)
    const[errorStatus,setErrorStatus]=useState(false)
    const navigate=useNavigate()
    const [searchParams]=useSearchParams()
    const [disableSaveButton,setDisableSaveButton]=useState(true)
    const [fileData,setFileData]=useState("")
    const AxiosAPIInstance=AxiosFunction()


  
    React.useEffect(()=>{
      
    const fetchUserData=async()=>{
       await AxiosAPIInstance.get("UserAuth/profile",{params:{UserEmail:User?.UserEmail}}).then((response)=>{
        Set_Form_Value({...Form_Value,
        FirstName:response?.data["User_FirstName"],
        LastName:response?.data["User_LastName"],
        email:response?.data["User_email"],
        phone_number:response?.data["User_phone_number"],
        UserName:response?.data["UserName"],
        DOB:response?.data["User_DOB"],
        AddressLine1:response?.data["AddressLine1"],
        AddressLine2:response?.data["AddressLine2"],
        City:response?.data["City"],
        State:response?.data["State"],
        Country:response?.data["Country"],
        Zip:response?.data["Zip"]})
       }).catch((error)=>{
        setErrorSuccessDialog(true)
        setErrorMsge(error?.response.data)
        setErrorStatus(true)
       })
      }
      fetchUserData()
    },[errorSuccessDialog])

    const handleErrorClose=(e)=>{
       e.preventDefault()
       setErrorStatus(false)
       setErrorSuccessDialog(false)
       setEditClicked(true)
       setDisableSaveButton(false)
       setErrorMsge("")
       setSuccessMsge("")
    }
    const handleSuccessClose=(e)=>{
      e.preventDefault()
      setErrorStatus(false)
      setErrorSuccessDialog(false)
      setDisableSaveButton(true)
      setErrorMsge("")
      setSuccessMsge("")
   }
    const handeEdit=(e)=>{
      e.preventDefault()
      setEditClicked(true)
      setDisableSaveButton(false)
    }
    const handleProfileImage=(e)=>{
      e.preventDefault()
    }
    //----------------------------------------------------------
 
    //----------------------------------------------------------------------------------------
   const handeSave=async(e)=>{
    e.preventDefault()
    setDisableSaveButton(true)
    if(Form_Value.FirstName!=="" && Form_Value.LastName!=="" && Form_Value.email!==""&& Form_Value.phone_number!=="")
    {
    await AxiosAPIInstance.patch("UserAuth/save",Form_Value).then(async(response)=>{
    setSuccessMsge(response?.data)
    setErrorSuccessDialog(true)
    setEditClicked(false)
    }).catch((error)=>{
     setErrorStatus(true)
     setEditClicked(true)
     setErrorMsge(error?.response.data)
     setErrorSuccessDialog(true)
     
    })
  }
  else{
    
    
    if(Form_Value.FirstName==="") setErrorMsge("Fisrt Name is required")
    else if(Form_Value.LastName==="") setErrorMsge("Last Name is required")
    else if(Form_Value.email==="") setErrorMsge("email is required")
    else if(Form_Value.phone_number==="") setErrorMsge("Phone Number is required")
    setErrorStatus(true)
    setErrorSuccessDialog(true)
  }
  }
   
    return(
        <>
        {<ApplicationBar></ApplicationBar>}
        {errorSuccessDialog===true&&(<Dialog
        open={errorSuccessDialog}
        onClose={errorStatus? handleErrorClose:handleSuccessClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
        {!errorStatus ? <Alert variant='filled' severity='success'>success</Alert>:<Alert variant='filled' severity='error'>error</Alert>}
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          {!errorStatus?successMsge:errorMsge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          errorStatus?handleErrorClose(e):handleSuccessClose(e)}}>OK</Button>
        </DialogActions>
      </Dialog>)}
        <Stack sx={{marginLeft:"100px",marginTop:"100px"}} direction="row" spacing={2}>
        <Stack>
         <Box sx={{width:"450px",marginTop:"120px"}}>
          <img style={{width:"350px",height:"350px"}} src="" placeholder alt="Profile Image"></img>
         </Box>
        </Stack>
            <Stack direction="column" spacing={4} sx={{marginLeft:"300px"}}>
            <Chip label="Personal Information" sx={{marginTop:"10px",fontSize:"20px",fontWeight:'bold',marginLeft:"200px",fontFamily:'Verdana (sans-serif)',width:"490px"}}></Chip>
                <Stack direction="row" spacing={2}>
            <TextField name="FirstName" size="small"variant="outlined" sx={{width:"250px"}} label="First Name" value={Form_Value.FirstName}  
            onChange={(e)=>{
              Set_Form_Value({...Form_Value,FirstName:e.target.value});
              }}  InputProps={{disabled:!editClicked,required:true}} ></TextField>
             <TextField name="LastName" size="small"sx={{width:"250px"}} label="Last Name" value={Form_Value.LastName}  onChange={(e)=>{
              Set_Form_Value({...Form_Value,LastName:e.target.value}) 
              }} InputProps={{disabled:!editClicked,required:true}}></TextField>
             <DatePicker name="DOB" label="Date Of Birth" sx={{width:"230px"}} value={Form_Value.DOB} maxDate={new Date()} renderInput={(params)=><TextField size="small" required sx={{width:"250px"}} {...params}/>} InputProps={{disabled:!editClicked,required:true}} onChange={(value)=>Set_Form_Value({...Form_Value,DOB:value})}>
               </DatePicker>
                </Stack>

                <Stack direction="row" spacing={2}>
                     <TextField name="phone_number"  size="small"sx={{width:"250px"}} label="Phone Number" value={Form_Value.phone_number} 
                     onChange={(e)=>{Set_Form_Value({...Form_Value,phone_number:e.target.value})
                       }}  InputProps={{disabled:!editClicked,startAdornment:(
                        <InputAdornment position="start">
                          <ContactPhoneIcon/>
                      </InputAdornment>)}}></TextField>
                    <TextField name="email" type="email" size="small"sx={{width:"250px"}} label="Email" value={User?.UserEmail} 
                    disabled
                   InputProps={{required:true,startAdornment:(
                      <InputAdornment position="start">
                              <EmailIcon/>
                      </InputAdornment>),
                      endAdornment:(
                        <Tooltip title="This Field is locked"><LockIcon></LockIcon></Tooltip>
                      )
                    }} ></TextField>
                      <TextField type="file" name="image" size="small" sx={{width:"250px"}}  onChange={(e)=>{setFileData(e.target.files[0])}} helperText="Profile Image Upload"></TextField>
                      <Tooltip title='Upload'><Avatar variant='rounded' sx={{bgcolor:[green[800]]}}><IconButton onClick={(e)=>{handleProfileImage(e)}} sx={{color:"white"}}><UploadFileIcon/></IconButton></Avatar></Tooltip>
               </Stack>
                <Stack direction="column" spacing={2}>
                <Stack sx={{marginLeft:"200px"}} spacing={2}>
        <Chip label="Address" sx={{marginTop:"10px",fontSize:"20px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"490px"}}></Chip>
        <Stack></Stack>
        </Stack>
                <Stack direction="row" spacing={2}>
                <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="Sreet Address" value={Form_Value.AddressLine1} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,AddressLine1:e.target.value})} InputProps={{disabled:!editClicked,startAdornment:(
                        <InputAdornment position="start">
                      <ContactMailIcon/>
                      </InputAdornment>)
                }}></TextField>
            <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="Apartment,suite, etc." value={Form_Value.AddressLine2} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,AddressLine2:e.target.value})} InputProps={{disabled:!editClicked,startAdornment:(
                        <InputAdornment position="start">
                      <ApartmentIcon/>
                      </InputAdornment>)
                }}></TextField>
            <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="City" value={Form_Value.City} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,City:e.target.value})} InputProps={{disabled:!editClicked}}></TextField>
                </Stack>
                <Stack direction="row" spacing={2}>
                <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="State/Province" value={Form_Value.State} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,State:e.target.value})} InputProps={{disabled:!editClicked}}></TextField>    
            <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="Country" value={Form_Value.Country} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Country:e.target.value})} InputProps={{disabled:!editClicked}}></TextField>
            <TextField  size="small"variant="outlined" sx={{width:"250px"}} label="Zip/Postal Code" value={Form_Value.Zip} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Zip:e.target.value})} InputProps={{disabled:!editClicked}}></TextField>
                </Stack>
                </Stack>
                <Stack direction="column" spacing={2}>
                       <Stack sx={{marginLeft:"200px"}} spacing={2}>
                        <Chip label="Login Credentials" sx={{marginTop:"10px",fontSize:"20px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"490px"}}></Chip>
                        <Stack></Stack>
                       </Stack>
                        <Stack direction="row" spacing={2}>
                        <TextField  size="small"sx={{width:"250px"}} label="UserName" value={Form_Value.UserName} required={true} 
                        onChange={(e)=>Set_Form_Value({...Form_Value,UserName:e.target.value})}  InputProps={{startAdornment:(<InputAdornment position='start'>
                        <AccountCircleIcon></AccountCircleIcon>
                    </InputAdornment>),
                    endAdornment:(
                      <Tooltip title="This Field is locked"><LockIcon></LockIcon></Tooltip>
                    )
                  }} disabled></TextField>
                         <TextField type="password" size="small" sx={{width:"250px"}} label="Password" value={Form_Value.Password} required={true} 
                        onChange={(e)=>Set_Form_Value({...Form_Value,Password:e.target.value})} disabled></TextField>
                        <IconButton><ModeEdit></ModeEdit></IconButton>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Stack sx={{width:"450px"}}></Stack>
                        <Button variant='contained' color="warning" size='medium' sx={{}} onClick={(e)=>{handeEdit(e)}} startIcon={<ModeEdit/>}>Edit</Button>
                       <Button variant='contained' color="success" size='medium' sx={{}} onClick={(e)=>{handeSave(e)}} disabled={disableSaveButton} startIcon={<SaveIcon/>}> Save</Button>
                    </Stack>
                </Stack>
              </Stack>
              </Stack>
        </>
    )
}
export default ProfilePage
