import {React} from 'react';
import { Text, View, StyleSheet,Image} from '@react-pdf/renderer';
import logo from "../Images/Aspetto_Login.png";
import HubZone from '../Images/HUBZone.png';
import Address from '../Images/Address.png'
const styles = StyleSheet.create({
    label:{
        marginTop: 24,
        flexDirection:'column'
    },
    titleContainer: {
        marginTop: 20,
    },
    reportTitle: {
        color: 'black',
        fontSize: 10,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'extrabold'
    },
    Quote_Title:{
        color: 'black',
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight:'bold',
    },
    logo: {
        width: 150,
        height: 50,
        marginLeft: 'auto',
        marginRight: "230px",
        alignContent:"center"
    },
    Hub:{
        width: 70,
        height: 70,
    },
    Image_con:{
        flexDirection:'row'
    },
    Address:{
        width: 230,
        height: 40,
    }
});

const InvoiceTitle = ({ invoice }) => (
    <>
     <View style={styles.Image_con}>
     <Image style={styles.Address} src={Address}></Image>
    <Image style={styles.logo} src={logo} />
     <Image style={styles.Hub} src={HubZone}></Image></View>
    {/* <View style={styles.titleContainer}>
        <Text style={styles.Quote_Title}>{invoice.title}</Text>
    </View> */}
    </>
);

export default InvoiceTitle;