import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useState} from "react";
import {Avatar, Button,Tooltip} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import PdfDocument from "../../../PDF/Main";
import { PDFDownloadLink} from '@react-pdf/renderer';
import { Link,createSearchParams,useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { orange,indigo } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

const color1=blueGrey[500]
const color2=blueGrey[50]

function SalesRow(props) {
    const PD= new Date()
    const today=new Intl.DateTimeFormat('en-US',{dateStyle:'medium'}).format(PD)
    const { row} = props;
    const navigate=useNavigate();
    const RFQID_Search=(RID,RStatus)=>{
            navigate({
            pathname:"/Dashboard/UpdateRFQ",
            search:createSearchParams({RID:row.RFQ_ID,RStatus:row.RFQ_Status,VNo:row.Version_No}).toString()
          });
    };
    const [open, setOpen] = React.useState(false);
    const[clicked,setClicked]=useState(false)
  const [InvoiceData,setInvoiceData]=useState({ 
    invoice_no:"",
    KO_name:"",
    KO_email:"",
    KO_phone: "",
    KO_address:"",
    trans_date:"",
    due_date: "",
    Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax:0,
    Shipping_Cost:0,
    Total_Cost:0,
    Shipping_Details:{
      Organization_Name:"",
      KO_name:"",
      Sales_Head_Info:{},
      Customer_Name:"",
      Customer_Email:"",
      Customer_Phone:"",
      Customer_Address:"",
      Shipping_Method:"",
      Shipping_Terms:"",
      Estimated_Delivery:"",
      Payment_Terms:"",
      Notes:""}})
  const HandlePDFClick=()=>{
    setClicked(true)
    setInvoiceData({...InvoiceData,
      invoice_num:String(row.Invoice_No),
      KO_name: row.KO_name,
      KO_email:row.KO_email,
      KO_phone: row.KO_phone,
      KO_address:row.KO_address,
      trans_date:today,
      due_date: row.Due_date,
      Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: row.Purchase_Orders,
      Tax:row.Tax,
      Shipping_Cost:row.Shipping_Cost,
      Total_Cost:row.Actual_Total_Cost_After_Margin,
      Shipping_Details:{
        Organization_Name:row.Organization_Name,
        KO_name:row.KO_name,
        Sales_Head_Info:{Name:row.Sales_Head_Name,Email:row.Sales_Head_Email,Phone:row.Sales_Head_Ph},
        Customer_Name:row.Customer_Name,
        Customer_Email:row.Customer_Email,
        Customer_Phone:row.Customer_Phone,
        Customer_Address:row.Customer_Address,
        Shipping_Method:row.Shipping_Method,
        Shipping_Terms:row.Shipping_Terms,
        Estimated_Delivery:row.Estimated_Delivery,
        Payment_Terms:row.Payment_Terms,
        Notes:row.Notes}
    })
  }
    return (
      <React.Fragment>
        <TableRow>
          <TableCell sx={{border:1,borderColor:'text.primary'}} align="center" scope="row">{row.Opportunity_Name}
          </TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.RFQ_ID}</TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary',width:"5%"}}align="center">{row.Contract_Vehicle}</TableCell>
         
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.KO_name}<br></br><b>Organization:</b>{row.Organization_Name}<br></br><b>Address:</b>{row.KO_address}<br></br>
          <b>Email:</b>{row.KO_email}<br></br><b>Phone:</b>{row.KO_phone}</Box></TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.Customer_Name}<br></br><b>Address:</b>{row.Customer_Address}<br></br>
          <b>Email:</b>{row.Customer_Email}<br></br><b>Phone:</b>{row.Customer_Phone}</Box></TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="Left">
          <b>Created By:</b>{row.Sales_Head_Name+", "+row.Sales_Head_Email}<br></br>
          <b>Created Date :</b>{String(row.Created_Date).split('T')[0]}<br></br>
          <b>Updated By:</b><br/>{row.Updated_By}<br/>
          <b>Updated Date :</b>{String(row.Last_Updated_Date).split('T')[0]}
         </TableCell>
          <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Actual_Total_Cost_After_Margin}</TableCell>
          <TableCell align="center">
          {row.RFQ_Status==="PENDING"&& <TableCell align="center"><Button sx={{width:"50px",fontSize:"10px"}} color="warning" type="text" variant="contained">{row.RFQ_Status}</Button></TableCell>}
          {row.RFQ_Status==="WON"&& <TableCell align="center"><Button sx={{width:"50px",fontSize:"10px"}} color="success" type="text" variant="contained">{row.RFQ_Status}</Button></TableCell>}
          {row.RFQ_Status==="LOST"&& <TableCell  align="center"><Button sx={{width:"50px",fontSize:"10px"}} color="error" type="text" variant="contained">{row.RFQ_Status}</Button></TableCell>}
          <Typography variant='h7' sx={{width:"50px",fontSize:"14px"}}>Revision: {row.Version_No}</Typography>
          <br>
          </br>
          <IconButton color="secondary" sx={{width:"50px",fontSize:"10px"}} variant="contained"><Avatar variant='rounded' sx={{bgcolor:[indigo[500]]}}><EditIcon  onClick={(e)=>{e.preventDefault();RFQID_Search()}} ></EditIcon>
          </Avatar></IconButton>
      <br></br>  <IconButton color="secondary" type="text" size="small" variant="contained" sx={{height:"45px",fontSize:"10px"}} ><Avatar variant='rounded' sx={{bgcolor:[orange[900]]}}><PictureAsPdfIcon onClick={HandlePDFClick}></PictureAsPdfIcon>
      </Avatar></IconButton>
          {clicked===true &&(<PDFDownloadLink onClick={HandlePDFClick} component="button" color="secondary"
              document={<PdfDocument invoicedata={InvoiceData}/>}
              fileName={row.Opportunity_Name+" "+row.RFQ_ID}>
              {({ blob, url, loading, error }) =>
                loading ? "Loading..." : "Download"
              }
            </PDFDownloadLink>)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1,}}>
                <Table aria-label="purchases" sx={{border:1,borderColor:'text.main'}}>
      
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  export default SalesRow