
import axios from 'axios'
import { setCredentials } from './Pages/Login_Registration/authSlice'
import { useDispatch} from 'react-redux'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './Pages/Login_Registration/authSlice'

const AxiosFunction=()=>{

const  AxiosAPIInstance=axios.create({
    baseURL:"https://www.aspettoportal.com/",
    withCredentials:true
}) 
// 
const dispatch=useDispatch()
const token=useSelector(selectCurrentToken)
// Add a request interceptor
AxiosAPIInstance.interceptors.request.use(
  async (config)=> {
            config.headers={
                'Authorization':`Bearer ${token}`,
                'Accept':'application/json',
                'Content-Type':'application/x-www-form-urlencoded'
            }
            return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// Add a response interceptor
AxiosAPIInstance.interceptors.response.use(
    response => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 403 && !originalRequest._retry) 
      {
        originalRequest._retry = true
        try
        {
         const response=await AxiosAPIInstance.get('/UserAuth/refresh')
         const {accessToken}=response?.data
         dispatch(setCredentials(accessToken))
         AxiosAPIInstance.defaults.headers.common['Authorization'] ='Bearer ' + accessToken;
         return AxiosAPIInstance(originalRequest)
        }
        catch(error)
        {
       
            return Promise.reject(error)

        }
      }
      return Promise.reject(error)
    }
  )
  return AxiosAPIInstance
}

export default AxiosFunction
