import React from "react";
import {Button, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem, Dialog,Alert} from '@mui/material';
import { useState,useEffect} from "react";
import {blueGrey, orange } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom'
import UserAuth from "../ProtectedRoute/userAuth";
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../Images/logo.png'
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';  
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken } from "../Login_Registration/authSlice";
import {AxiosGlobalAPIInstance} from "../../axiosGlobalInstance";
import { logOut } from "../Login_Registration/authSlice";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
function ApplicationBar()
{   
  const [open1,setOpen1]=useState(false)
    const [anchorEl2,setAnchorEl2]=useState(null)
    const [open2,setOpen2]=useState(false)
    const [anchorEl3,setAnchorEl3]=useState(null)
    const [open3,setOpen3]=useState(false)
    const [anchorEl4,setAnchorEl4]=useState(null)
    const [open4,setOpen4]=useState(false)
    const [anchorEl5,setAnchorEl5]=useState(null)
    const [open5,setOpen5]=useState(false)
    const {UserName,UserEmail}=UserAuth()
    const [currentTabColor,setCurrentTabColor]=useState("Home")
    const [error,setError]=useState("")
    const [success,setSuccess]=useState("")
    const [errorSuccessDialog,setErrorSuccessDialog]=useState(false)
    const dispatch=useDispatch()

    const token=useSelector(selectCurrentToken)

    const handleRFQClick=(event)=>{
      setCurrentTabColor("RFQ")
      setOpen2(true)
      event.preventDefault()
      setAnchorEl2(event.currentTarget)
    }
    const handleRFQClose=(e)=>{
      setOpen2(false)
      setAnchorEl2(null)
    }
    const handleRFIClick=(event)=>{
      setOpen3(true)
      setCurrentTabColor("RFI")
      event.preventDefault()
      setAnchorEl3(event.currentTarget)
    }
    const handleRFIClose=(e)=>{
      setOpen3(false)
      setAnchorEl3(null)
    }
    const handleUserClick=(event)=>{
      setOpen4(true)
      event.preventDefault()
      setAnchorEl4(event.currentTarget)
    }
    const handleUserClose=(e)=>{
      setOpen4(false)
      setAnchorEl4(null)
    }
    const handleDraftsClick=(event)=>{
      setOpen5(true)
      event.preventDefault()
      setAnchorEl5(event.currentTarget)
    }
    const handleDraftsClose=(e)=>{
      setOpen5(false)
      setAnchorEl5(null)
    }
    const navigate = useNavigate()
    const { pathname } = useLocation()
  

    const handleClose = (e) => {
      setErrorSuccessDialog(false);
      setSuccess("")
      setError("")
    }; 
    

    const handleLogOut=(e)=>{
      AxiosGlobalAPIInstance.post('/UserAuth/logout').then((response)=>{
        dispatch(logOut())
        navigate('/',{replace:false})
      }).catch((error)=>{
      })
      
    }
    const handleProfile=(e)=>{
      e.preventDefault();
      
    }
    

return(
  <>
{errorSuccessDialog===true&&(<Dialog
        open={errorSuccessDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        <Alert variant='filled' severity={error?'error':'success'}>{error?error:success}</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {error? error:success}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e)=>{
          if(success!=='')
          {
            navigate('/',{replace:false})
          }
          }}>OK</Button>
        </DialogActions>
      </Dialog>)}
  <AppBar  sx={{backgroundColor:theme.palette.primary.main}}>
  {/* sx={{backgroundColor:theme.palette.primary.main}} */}
  <Toolbar>
    <IconButton size='large' edge='start' color='inherit' sx={{marginLeft:"20px"}} onClick={(e)=>{e.preventDefault();navigate("/Dashboard")}} >
     <img src={Logo} alt="ASPETTO" height="35px" width="140px"></img>
    </IconButton>
    <Typography component="p" sx={{color:'white',flexGrow:1,fontSize:"25px", fontWeight:"bold",}}></Typography>
    <Stack direction='row' edge='end'>
    <Tooltip title="Go To Home Page"><IconButton variant="text" sx={{color:'white'}} 
      onClick={
      (e)=>{
      e.preventDefault();
      navigate("/Dashboard")
      }} ><Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><HomeIcon></HomeIcon></Avatar></IconButton></Tooltip>
    <IconButton variant="text" sx={{color:'white',fontSize:"16px",width:"70px"}} id="RFQ-Button" onClick={handleRFQClick} aria-control={open2?'RFQ-Menu':undefined} 
         aria-haspopup='true' aria-expanded={open2?'true':undefined} endIcon={open2?<ArrowDropUpIcon></ArrowDropUpIcon>:<ArrowDropDownIcon></ArrowDropDownIcon>}>RFQs</IconButton>

    <IconButton variant="text" sx={{color:'white',fontSize:"16px",width:"70px"}} id="RFI-Button" onClick={handleRFIClick}  aria-control={open3?'RFI-Menu':undefined} 
         aria-haspopup='true' aria-expanded={open3?'true':undefined} endIcon={open3?<ArrowDropUpIcon></ArrowDropUpIcon>:<ArrowDropDownIcon></ArrowDropDownIcon>}>RFIs</IconButton>
      <IconButton variant="text" sx={{color:'white',fontSize:"16px",width:"70px"}} onClick={(e)=>{handleDraftsClick(e)}} aria-control={open5?'Drafts-Menu':undefined} 
         aria-haspopup='true' aria-expanded={open5?'true':undefined} endIcon={open5?<ArrowDropUpIcon></ArrowDropUpIcon>:<ArrowDropDownIcon></ArrowDropDownIcon>}>Drafts</IconButton>
    <IconButton variant="text" sx={{color:'white',fontSize:"16px",width:"150px"}} onClick={(e)=>{e.preventDefault(); navigate("/Dashboard/POsSearch")}}>Purchase Orders</IconButton>
    <IconButton variant="text" sx={{color:'white',width:"60px",fontSize:"16px"}} >SKUs</IconButton>
    <Tooltip title="Notifications"><IconButton variant="text" sx={{color:'white'}} ><Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><NotificationsIcon/></Avatar></IconButton></Tooltip>
    <Tooltip title="My Performance"><IconButton variant="text" sx={{color:'white'}} ><Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><InsertChartIcon/></Avatar></IconButton></Tooltip>
    <IconButton variant="text" sx={{color:'white',width:"150px"}} onClick={handleUserClick}  aria-control={open4?'User-Menu':undefined} 
         aria-haspopup='true' aria-expanded={open4?'true':undefined} endIcon={open4?<ArrowDropUpIcon></ArrowDropUpIcon>:<ArrowDropDownIcon></ArrowDropDownIcon>}>
         <StyledBadge
         overlap="circular"
         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
         variant="dot">
         <Avatar alt={UserName} sx={{height:"30px",width:"30px"}} src="" />
       </StyledBadge>
       <Button variant="text" sx={{color:'white'}}>{UserName}</Button></IconButton>
        </Stack>
        
        <Menu id="RFQ-Menu" anchorEl={anchorEl2} open={open2} MenuListProps={{'aria-labelledby':'RFQ-Button'}} onClose={handleRFQClose} anchorOrigin={{
          vertical:'bottom',horizontal:'right'}}> 
          <MenuItem>
          <Button variant="text"  onClick={(e)=>{navigate("/Dashboard/CreateRFQ")}} size="small" sx={{color:theme.palette.primary.main,height:"35px"}}> <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><AssignmentIcon />
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>New Quote</Button></Button>
          </MenuItem>
          <MenuItem >
          <Button variant="text"  onClick={(e)=>{ navigate("/Dashboard/RFQsSearch")}} size="small" sx={{color:theme.palette.primary.main,height:"35px"}}> <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><ModeEditIcon/>
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>Retrieve/Update</Button> </Button>
          </MenuItem>
        </Menu>
        <Menu id="RFI-Menu" anchorEl={anchorEl3} open={open3} onClose={handleRFIClose} MenuListProps={{'aria-labelledby':'RFI-Button'}} anchorOrigin={{
          vertical:'bottom',horizontal:'right'
        }}>
        <MenuItem>
          <Button variant="text" onClick={(e)=>{navigate("/Dashboard/CreateRFI")}} size="small" sx={{color:theme.palette.primary.main,height:"35px"}}> <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><AssignmentIcon />
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>New RFI</Button></Button>
          </MenuItem>
          <MenuItem >
          <Button variant="text" onClick={(e)=>{navigate("/Dashboard/RFIsSearch")}} size="small" sx={{color:theme.palette.primary.main,height:"35px"}}> <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><ModeEditIcon/>
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>Retrieve/Update</Button> </Button>
          </MenuItem>
        </Menu>
        <Menu id="Drafts-Menu" anchorEl={anchorEl5} open={open5} MenuListProps={{'aria-labelledby':'Drafts-Button'}} onClose={handleDraftsClose} anchorOrigin={{
          vertical:'bottom',horizontal:'right'
        }}>
        <MenuItem>
          <Button variant="text" onClick={(e)=>{navigate("/Dashboard/RFQDrafts")}} size="small" sx={{color:theme.palette.primary.main,height:"35px"}}> <Avatar sx={{ bgcolor:theme.palette.primary.main,width:"30px",height:"30px" }}><AssignmentIcon />
      </Avatar><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>RFQ Drafts</Button></Button>
          </MenuItem>
        </Menu>
        <Menu
        anchorEl={anchorEl4}
        id="account-menu"
        open={open4}
        onClick={handleUserClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <IconButton sx={{fontSize:"16px",color:"black"}} onClick={
          ()=>{navigate("/Dashboard/Profile")}}>
            <Avatar fontSize="small"/> Profile
          </IconButton>
        </MenuItem>
        {/* <MenuItem>
          <Avatar /> My account
        </MenuItem> */}
        <Divider />
        {/* <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
           <MenuItem>
          <IconButton sx={{fontSize:"16px",color:"black"}} onClick={
          (e)=>{handleLogOut(e)}}>
                <ListItemIcon>
         <Logout fontSize="small"/>
          </ListItemIcon>
          Logout
          </IconButton>
        </MenuItem>
      </Menu>
        {/* <Menu id="User-Menu" anchorEl={anchorEl4} open={open4} MenuListProps={{'aria-labelledby':'RFI-Button'}} onClose={handleUserClose} anchorOrigin={{
          vertical:'bottom',horizontal:'right'
        }}>
            <MenuItem><IconButton size="small" variant="text" sx={{color:'white',height:"20px"}} onClick= {handleProfile}>
              <PersonIcon sx={{color:theme.palette.primary.main,height:"35px"}}/><Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>Profile</Button></IconButton></MenuItem>
         <MenuItem><IconButton size="small" variant="text" sx={{color:'white',height:"20px"}} onClick= {handleLogOut}><LogoutIcon size="small" sx={{color:theme.palette.primary.main,height:"35px"}}/>
         <Button variant="text" size="small" sx={{color:theme.palette.primary.main}}>LOGOUT</Button></IconButton></MenuItem>
        </Menu> */}
      </Toolbar>
    </AppBar>
    </>
)
}

export default ApplicationBar