import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useState} from "react";
import axios from "../../axiosCustomInstance";
import {Button, Stack,TextField,InputAdornment} from '@mui/material'
import { DatePicker } from "@mui/x-date-pickers";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {createSearchParams,useNavigate} from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AxiosFunction from "../../axiosCustomInstance";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


function Row(props) {
  const PD= new Date()
  const { row} = props;
  const navigate=useNavigate();
  const RFISolicitation_Search=(RSocNum)=>{
          navigate({
          pathname:"/Dashboard/UpdateRFI",
          search:createSearchParams({RSocNum:row.Solicitation_Number}).toString()
        });
  };

const [open, setOpen] = React.useState(false);
const[clicked,setClicked]=useState(false)
const [InvoiceData,setInvoiceData]=useState({ 
  invoice_no:"",
  KO_name:"",
  KO_email:"",
  KO_phone: "",
  KO_address:"",
  trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
  due_date: "",
  Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
  items: [{}],
  Other_Cost:{Tax:0,Shipping_Cost:0},
  Shipping_Details:{
    Organization_Name:"",
    KO_name:"",
    Sales_Head_Info:{},
    Customer_Name:"",
    Customer_Email:"",
    Customer_Phone:"",
    Customer_Address:"",
    Shipping_Method:"",
    Shipping_Terms:"",
    Estimated_Delivery:"",
    Payment_Terms:"",
    Notes:""}})
const HandlePDFClick=()=>{
  setClicked(true)
  setInvoiceData({...InvoiceData,
    invoice_num:String(row.Invoice_No)+"R"+String(row.Version_No),
    KO_name: row.KO_name,
    KO_email:row.KO_email,
    KO_phone: row.KO_phone,
    KO_address:row.KO_address,
    trans_date:(String(Number(PD.getMonth())+1) +"-"+String(PD.getDate()) +"-"+String(PD.getFullYear())),
    due_date: row.Due_date,
    Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: row.Purchase_Orders,
    Tax:row.Tax,
    Shipping_Cost:row.Shipping_Cost,
    Shipping_Details:{
      Organization_Name:row.Organization_Name,
      KO_name:row.KO_name,
      Sales_Head_Info:{Name:row.Sales_Head_Name,Email:row.Sales_Head_Email,Phone:row.Sales_Head_Ph},
      Customer_Name:row.Customer_Name,
      Customer_Email:row.Customer_Email,
      Customer_Phone:row.Customer_Phone,
      Customer_Address:row.Customer_Address,
      Shipping_Method:row.Shipping_Method,
      Shipping_Terms:row.Shipping_Terms,
      Estimated_Delivery:row.Estimated_Delivery,
      Payment_Terms:row.Payment_Terms,
      Notes:row.Notes}
  })
}
  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{border:1,borderColor:'text.primary'}} align="center" scope="row">{row.Opportunity_Name}
        </TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Solicitation_Number}</TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Contract_Vehicle}</TableCell>
       
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center"><Box sx={{textAlign:"left"}}><b>Name:</b>{row.Contracting_Officer_Name}<br></br><b>Address:</b>{row.KO_address}<br></br>
        <b>Email:</b>{row.KO_email}<br></br><b>Phone:</b>{row.KO_phone}</Box></TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary',width:"15%"}}align="Left">
        <b>SAM.Gov Link: </b><IconButton sx={{width:"50px",fontSize:"10px"}} href= {row.Sam_gov_link} target="_blank" variant="contained"><LinkIcon variant="contained" color="primary" ></LinkIcon></IconButton>
         <br></br>
         <b>Govwin Link: </b><IconButton sx={{width:"50px",fontSize:"10px"}} href= {row.Govwin_Link} target="_blank"  variant="contained"><LinkIcon variant="contained" target="_blank" color="primary" ></LinkIcon></IconButton>
        </TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary',width:"15%"}}align="Left">
        <b>Published Date :</b>{String(row.Published_date)}<br></br>
        <b>Due Date :</b>{String(row.Due_date)}</TableCell>
        <TableCell sx={{border:1,borderColor:'text.primary'}}align="center">{row.Estimated_$_Amount}</TableCell>
        <TableCell align="center">
          <IconButton color="secondary" sx={{width:"80px",height:"80px",fontSize:"10px"}} variant="contained"><EditTwoToneIcon  onClick={(e)=>{e.preventDefault();RFISolicitation_Search(row.Solicitation_Number)}} ></EditTwoToneIcon></IconButton>
       </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1,}}>
              <Table aria-label="purchases" sx={{border:1,borderColor:'text.main'}}>
    
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function RFIs_Search_Update() {
  const [GetClick,SetGetClick]=useState(false);
  const [totalData,setTotalData]=useState([{}])
  const [dueDate,setdueDate]=useState({From_Date: new Date(),To_Date: new Date()})
  const [dueDateChecked,setdueDateChecked]=useState(false)
  const [opportunityName,setOpportunityName]=useState("")
  const [opportunityNameChecked,setOpportunityNameChecked]=useState(false)
  const [getRFISoliciNum,setgetRFISoliciNum]=useState("")
  const [getRFISoliciNumChecked,setgetRFISoliciNumChecked]=useState(false)
  const [DateValue, SetDateValue]=useState({From_Date: new Date(),To_Date: new Date()})
  const [getDateRangeChecked,setGetDateRangeChecked]=useState(false)
  const [fieldchecked,setFieldChecked]=useState("");
  const [open1, setOpen1] = useState(false);
  const [stus,setStus]=useState("WON")
  const [dis,setDis]=useState(false)
  const [oppError,setOppError]=useState(false)
  const [dateError,setDateError]=useState(false)
  const [dueDateError,setdueDateError]=useState(false)
  const [rfiSolicNumError,setrfiSolicNumError]=useState(false)
  const [searchFilterError,setSearchFilterError]=useState(false)
  const [oppErrorMsge,setOppErrorMsge]=useState("")
  const [dateErrorMsge,setDateErrorMsge]=useState("")
  const [dueDateErrorMsge,setdueDateErrorMsge]=useState("")
  const [rfiSolcNumErrorMsge,setrfiSolcNumErrorMsge]=useState("")
  const [searchFilterErrorMsge,setSearchFilterErrorMsge]=useState("")
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const AxiosAPIInstance=AxiosFunction()


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (e) => {
    setOpen1(false);
    setrfiSolicNumError(false);
    setOppError(false);
    setdueDateError(false)
    setSearchFilterError(false)
    setOppErrorMsge("");
    setdueDateError("");
    setrfiSolcNumErrorMsge("")
  };
  useEffect(()=>{
    setTotalData([{}]);
    SetGetClick(false);
  },[stus])
  const handleRFISoliciNumChange=(e)=>{
    SetGetClick(false);
    setgetRFISoliciNumChecked(e.target.checked);
    if(e.target.checked===true)
    { 
      setOpportunityNameChecked(false);
      setdueDateChecked(false);
      setGetDateRangeChecked(false);
      setFieldChecked("RFQID");
      SetDateValue({From_Date: new Date(),To_Date: new Date()})
      setdueDate("");
      setOpportunityName("");
      
    }
    else
    {
      setFieldChecked("");
    }

  }
  const handleOpporNameChange=(e)=>{
    SetGetClick(false);
    setOpportunityNameChecked(e.target.checked);
    if(e.target.checked===true)
    { 
      setgetRFISoliciNumChecked(false);
      setdueDateChecked(false);
      setGetDateRangeChecked(false)
      setFieldChecked("OpportunityName");
      setgetRFISoliciNum("");
      setdueDate("");
      SetDateValue({From_Date: new Date(),To_Date: new Date()})
      
    }
    else
    {
      setFieldChecked("");
    }

  }
  const handleDueDateChange=(e)=>{
    SetGetClick(false);
    setdueDateChecked(e.target.checked);
    if(e.target.checked===true)
    { 
      setgetRFISoliciNumChecked(false);
      setOpportunityNameChecked(false)
      setGetDateRangeChecked(false)
      setFieldChecked("DueDate");
      setgetRFISoliciNum("");
      setOpportunityName("");
      SetDateValue({From_Date: new Date(),To_Date: new Date()});
    }
    else{
      setFieldChecked("");
    }
  }
  const handleDateRangeChange=(e)=>{
    SetGetClick(false);
    setGetDateRangeChecked(e.target.checked);
    if(e.target.checked===true)
    { 
      setgetRFISoliciNumChecked(false);
      setdueDateChecked(false)
      setOpportunityNameChecked(false)
      setFieldChecked("DateSearch");
      setgetRFISoliciNum("");
      setOpportunityName("");
      setdueDate("")
    }
    else
    {
      setFieldChecked("");
    }
  }
  const Get_Data_Click=async (e)=>{
    e.preventDefault();
    setDis(true)
   if(fieldchecked!=='')
   {
    if(getRFISoliciNumChecked===true)
        {  
          if(getRFISoliciNum!=='')
        {
          await AxiosAPIInstance.get("/RFIsSearch/SolicitationNumber",{params:{Solicitation_Number:getRFISoliciNum}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
                setTotalData(response?.data)
                SetGetClick(true)
              }
              else{
                SetGetClick(false)
                setOppError(true)
                setOppErrorMsge("No Records Found")
               
              }
            }
          ).catch((err)=>{
            SetGetClick(false)
            setrfiSolicNumError(true)
            setrfiSolcNumErrorMsge(err.response?.data)
          }).finally(()=>{
            setDis(false)
          })
        }
        else {
          SetGetClick(false)
          setrfiSolicNumError(true)
          setrfiSolcNumErrorMsge("Solicitation Number is Required")
          setDis(false)
            }
        }
    else if(fieldchecked==='OpportunityName')
        {
          if(String(opportunityName).trim().length>=5)
          {
          await AxiosAPIInstance.get("/RFIsSearch/OpportunityName",{params:{Opportunity_Name:String(opportunityName).trim()}}).then(
            (response)=>{
            
              if(response?.data.length>0)
              {
                setTotalData(response?.data)
                SetGetClick(true)
              }
              else{
                SetGetClick(false)
                setOppError(true)
                setOppErrorMsge("No Records Found")
              }
            }
          ).catch((err)=>{
            SetGetClick(false)
            setOppError(true);
            setOppErrorMsge(err.response?.data);
          }).finally(()=>{
            setDis(false)
          })
        }
        else
        { SetGetClick(false)
          SetGetClick(false);
          setDis(false);
          setOppError(true);
          setOppErrorMsge("Opportunity Name should contain atleast 4 letters");
        }
        }
    else if(dueDateChecked===true)
        {
          if(dueDate!=='')
          {
          await AxiosAPIInstance.get("/RFIsSearch/DueDate",{params:{From:dueDate.From_Date,To:dueDate.To_Date}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
                setTotalData(response?.data)
                SetGetClick(true)
              }
              else{
                SetGetClick(false)
                setdueDateError(true)
                setdueDateErrorMsge("No Records Found")
              }
            }
            
          ).catch((err)=>{
            SetGetClick(false)
            setdueDateError(true)
            setdueDateErrorMsge(err.response?.data)
          }).finally(()=>{
            setDis(false)
          })
        }
        else {
              SetGetClick(false)
              setdueDateError(true)
              setdueDateErrorMsge("Due Date is Required")
              setDis(false)
              }
        }
    else if(fieldchecked=='DateSearch')
        {
          setgetRFISoliciNumChecked(false)
          await AxiosAPIInstance.get("/RFIsSearch/DateSearch",{params:{From:DateValue.From_Date,To:DateValue.To_Date}}).then(
            (response)=>{
              if(response?.data.length>0)
              {
                setTotalData(response?.data)
                SetGetClick(true)
              }
              else{
                SetGetClick(false)
                setDateError(true)
                setDateErrorMsge("No Records Found")
              }
            }
          ).catch((err)=>{
            SetGetClick(false)
            alert(err.response?.data)
          }).finally(()=>{
            setDis(false)
          })
        }
        else
        {
          SetGetClick(false)
          setDis(false)
        }
      }
      else
      {
        SetGetClick(false)
        setSearchFilterError(true)
        setSearchFilterErrorMsge("No Search Filter is Selected")
        setDis(false)
      }
    }

  return (
    <>
       {<ApplicationBar></ApplicationBar>}
  {open1===true&&(<Dialog
      open={open1}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to exit this page?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All the changes will be lost if you decide to exit this page
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="Yes" onClick={handleClose} href="/Dashboard">Yes</Button>
        <Button name="No" onClick={handleClose}  autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>)}
    {rfiSolicNumError===true&&(<Dialog
      open={rfiSolicNumError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant="filled" severity={rfiSolcNumErrorMsge==="No Records Found"?"warning":"error"}>{rfiSolcNumErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
       {rfiSolcNumErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {oppError===true&&(<Dialog
      open={oppError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"400px"}} variant="filled" severity={oppErrorMsge==="No Records Found"?"warning":"error"}>{oppErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
          {oppErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {dueDateError===true&&(<Dialog
      open={dueDateError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant="filled" severity={dueDateErrorMsge==="No Records Found"?"warning":"error"}>{dueDateErrorMsge==="No Records Found"?"status":"error"}</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
        {dueDateErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    {searchFilterError===true&&(<Dialog
      open={searchFilterError}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
      <Alert sx={{width:"300px"}} variant="filled" severity="error">ERROR</Alert>
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{textAlign:"center"}}>
        {searchFilterErrorMsge}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} >OK</Button>
      </DialogActions>
    </Dialog>)}
    <Stack direction='column' spacing={2}>
      <Stack sx={{marginTop:"80px",marginLeft:"440px",}}>

        <Typography  sx={{fontSize:"30px",marginLeft:"0px",fontWeight:'bold',fontFamily:'Verdana (sans-serif)',width:"830px"}}>Request For Information/Sources Sought(RFIs)</Typography>
        </Stack>
          <Paper sx={{}} autoComplete='off' elevation={1}>
        <Stack sx={{marginTop:'40px',marginLeft:'150px'}} direction='row' spacing={3}>
        <Stack direction='column' spacing={3}>
        <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" name="RFI_Solicitation_Number_Search"  checked={getRFISoliciNumChecked} onChange={(e)=>{handleRFISoliciNumChange(e)}}/>} label="Solicitation Number" />
       </FormGroup>
        <TextField required sx={{width:"200px"}} size="small" disabled={!getRFISoliciNumChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
         
            </InputAdornment>
          ),}} label="Solicitation Number" value={getRFISoliciNum} onChange={(e)=>{setgetRFISoliciNum(e.target.value)}}></TextField></Stack><Stack sx={{width:"50px"}}></Stack>
           <Stack direction='column' spacing={3}>
          <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" name="Opportunity_Name_Search" checked={opportunityNameChecked} onChange={(e)=>{handleOpporNameChange(e)}}/>} label="Opportunity Name" />
       </FormGroup>
        <TextField  required sx={{width:"200px"}} size="small" disabled={!opportunityNameChecked} InputProps={{startAdornment: (
            <InputAdornment position="start">
             
            </InputAdornment>
          ),}} label="Opportunity Name" value={opportunityName} onChange={(e)=>{setOpportunityName(e.target.value)}} ></TextField></Stack><Stack sx={{width:"50px"}}></Stack>
          <Stack direction='column' spacing={3}>
          <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" name="Due_Date_Search" checked={dueDateChecked} onChange={(e)=>{handleDueDateChange(e)}}/>} label="Due Date" />
       </FormGroup>
       <DatePicker disabled={!dueDateChecked} size="small" label="From"  maxDate={new Date()} required  value={dueDate.From_Date} onChange={(value)=>setdueDate({...dueDate,From_Date:value})}  renderInput={(params)=><TextField size="small"  disabled={!dueDateChecked} {...params} />}>
        </DatePicker>
        <DatePicker  disabled={!dueDateChecked}  label="To" minDate={dueDate.From_Date} required  value={dueDate.To_Date} onChange={(value)=>setdueDate({...dueDate,To_Date:value})} renderInput={(params)=><TextField disabled={!dueDateChecked}  variant="outlined" size="small" {...params} />}>
        </DatePicker></Stack><Stack sx={{width:"50px"}}></Stack>
           <Stack direction='column' spacing={3}>
           <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" name="DateSearch" checked={getDateRangeChecked} onChange={(e)=>{handleDateRangeChange(e)}}/>} label="Created Date" />
       </FormGroup>
           <Stack direction='column' spacing={3}>
        <DatePicker disabled={!getDateRangeChecked} size="small" label="From"  maxDate={new Date()} required  value={DateValue.From_Date} onChange={(value)=>SetDateValue({...DateValue,From_Date:value})}  renderInput={(params)=><TextField size="small"  disabled={!getDateRangeChecked} {...params} />}>
        </DatePicker>
        <DatePicker  disabled={!getDateRangeChecked}  label="To" minDate={DateValue.From_Date} required  value={DateValue.To_Date} onChange={(value)=>SetDateValue({...DateValue,To_Date:value})} renderInput={(params)=><TextField disabled={!getDateRangeChecked}  variant="outlined" size="small" {...params} />}>
        </DatePicker></Stack></Stack>
        </Stack>
      
        <Stack sx={{marginLeft:"600px",marginTop:"30px"}}>       
      </Stack>
        <Stack ><Button sx={{width:'250px',marginLeft:'600px',marginTop:"30px",marginBottom:"40px"}} variant="contained" color='success' disabled={dis} onClick={(e)=>Get_Data_Click(e)}>Get</Button></Stack>   </Paper>
        <Stack></Stack>
        {GetClick ===true && (getDateRangeChecked===true || getRFISoliciNumChecked===true || dueDateChecked===true || opportunityNameChecked===true)&&
        (<TableContainer>
        <Table aria-label="POs_DateRange" sx={{border:1,borderColor:'black',width:"100%"}}>
          <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
            <TableRow>
        
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Opportunity Name</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Solicitation Number</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Contract Vehicle</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Contracting Officer's Info</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">URLs</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Published Date & Due Date</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Dollar Amount($)</TableCell>
              <TableCell sx={{fontWeight:'bold',color:"white",fontFamily:'Verdana (sans-serif)'}} align="center">Proposal Stage/Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(rowsPerPage > 0
            ? totalData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : totalData
          )?.map((row,index) => (
              <Row key={index} row={row}/>
            ))}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          
              count={totalData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>)}
     
      </Stack>
      </>
    );
  }
  export default RFIs_Search_Update