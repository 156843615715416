import {Button,TextField,Paper, Stack,Typography,Tooltip} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState,useEffect} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import { PDFDownloadLink} from '@react-pdf/renderer';
import { DatePicker } from "@mui/x-date-pickers";
import PdfDocument from "../PDF/Main";
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import ApplicationBar from "../AppBar/ApplicationBar";
import UserAuth from '../ProtectedRoute/userAuth';
import LockIcon from '@mui/icons-material/Lock';
import AxiosFunction from "../../axiosCustomInstance"
import { selectCurrentToken} from '../Login_Registration/authSlice';
import { useSelector } from 'react-redux';
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});


function  RFQ_CLIENT_INPUT_UPDATE()
{

const PD= new Date()
const today=new Intl.DateTimeFormat('en-US',{dateStyle:'short'}).format(PD)
const {UserName,UserEmail}=UserAuth()
const AxiosAPIInstance=AxiosFunction()

//---------------------------Defining Form State Variable--------------------------------------
const [Form_Value,Set_Form_Value]=useState({Opportunity_Name:"",RFQ_ID:'',Contract_Vehicle:'GSA',KO_name:'',KO_email:'',
KO_phone:'',KO_address:'',Published_date:null,Due_date:null,Brief:'',Organization_Name:'',Customer_Name:'',Customer_Email:'',Customer_Phone:'',Customer_Address:'',Shipping_Method:'',Sales_Head_Name:'',Sales_Head_Email:'',Sales_Head_Ph:"",
Shipping_Terms:'FOB Origin',Estimated_Delivery:'',Payment_Terms:'NET30',Notes:'',Created_Date:'',Shipping_Cost:parseFloat(0).toFixed(3),Tax:parseFloat(0).toFixed(3),
Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
RFQ_Status:'PENDING'
,Updated_By:'',Version_No:parseInt(0),Updating_Comments:'N/A'})
//------------Defining SKU State Variable------------------------------------------------------
const [New_SKUT,Set_New_SKU]=useState([{Vendor_Partner_name:'',SKU_ID:'',SKU_Description:'',QTY:parseInt(0),Aspetto_Unit_Cost:parseFloat(0).toFixed(3),Unit_Of_Measure:'ea',RFQRFQID:'',Margin_Percent:parseFloat().toFixed(3),Customer_Unit_Cost:parseFloat(0).toFixed(3),Total_SKU_Cost:parseFloat(0).toFixed(3),
RFQ_Created_Date:Form_Value.Created_Date,
RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
SKU_Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
SKU_Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
SKU_Created_By:'TBD',
SKU_Updated_By:'TBD',Version_No:Form_Value.Version_No}])
//----------------------Defining Cost State Variable------------------------------------------
const [TotalCost,SetTotalCost]=useState({Actual_Total_Cost_Before_Margin:parseFloat(0.00).toFixed(3),
  Actual_Total_Cost_After_Margin:parseFloat(0.00 ).toFixed(3),Discount_Applied:parseFloat(0).toFixed(3),
  Final_Total_Cost_After_Dicscount:parseFloat(0).toFixed(3)})
  

const[clicked,setClicked]=useState(false)
const[shipp,setShipp]=useState(false)
const [tax,setTax]=useState(false)
const [fileName,setFileName]=useState("")

const [searchParams]=useSearchParams();
let [RFQ_Data,set_RFQ_Data]=useState({})
const [dis,setDis]=useState(false)
const navigate=useNavigate()
const[rfqMssge,setRFQMssge]=useState('')
const [successDialog,setSuccessDialog]=useState(false)
const [errorDialog,setErrorDialog]=useState(false)
const [open, setOpen] = useState(false);
const [areYouSureDialog,setAreYouSureDialog]=useState(false)
const [areYouSureSubmitDialogDecision,setAreYouSureSubmitDialogDecision]=useState(false)
const [updatedByEmail,setUpdatedByEmail]=useState(UserEmail)
useEffect(()=>
{
  async function fetchData(){
    await AxiosAPIInstance.get("/RFQsSearch/RFQIDWithVersonNo",{params:{RFQ_ID:searchParams.get("RID"),RFQ_Status:searchParams.get("RStatus"),Version_No:searchParams.get("VNo")}}).then((result)=>{ 
      Set_Form_Value({...Form_Value,Opportunity_Name:result?.data[0]['Opportunity_Name'],
      RFQ_ID:result?.data[0]['RFQ_ID'],
      Invoice_No:result?.data[0]['Invoice_No'],
      Contract_Vehicle:result?.data[0]['Contract_Vehicle'],
      KO_name:result?.data[0]['KO_name'],
      KO_email:result?.data[0]['KO_email'],
      KO_phone:result?.data[0]['KO_phone'],
      KO_address:result?.data[0]['KO_address'],
      Published_date:result?.data[0]['Published_date'],
      Due_date:result?.data[0]['Due_date'],
      Brief:result?.data[0]['Brief'],
      Organization_Name:result?.data[0]['Organization_Name'],
      Customer_Name:result?.data[0]['Customer_Name'],
      Customer_Email:result?.data[0]['Customer_Email'],
      Customer_Phone:result?.data[0]['Customer_Phone'],
      Customer_Address:result?.data[0]['Customer_Address'],
      Shipping_Method:result?.data[0]['Shipping_Method'],
      Sales_Head_Name:result?.data[0]['Sales_Head_Name'],
      Sales_Head_Email:result?.data[0]['Sales_Head_Email']||"sales@aspettoinc.com",
      Sales_Head_Ph:result?.data[0]['Sales_Head_Ph'],
      Shipping_Terms:result?.data[0]['Shipping_Terms'],
      Estimated_Delivery:result?.data[0]['Estimated_Delivery'],
      Payment_Terms:result?.data[0]['Payment_Terms'],
      Notes:result?.data[0]['Notes'],
      Shipping_Cost:Number(result?.data[0]['Shipping_Cost']),
      Tax:Number(result?.data[0]['Tax']),
      Created_Date:result?.data[0]['Created_Date'],
      Last_Updated_Date:result?.data[0]['Last_Updated_Date'],
      RFQ_Status:result?.data[0]['RFQ_Status'],
      Updated_By:UserEmail||"sales@aspettoinc.com",
      Version_No:result?.data[0]['Version_No'],
      Updating_Comments:result?.data[0]['Updating_Comments']
      })
      setShipp(true)
      setTax(true)
      Set_New_SKU(result?.data[0]["Purchase_Orders"])
      const skusl=[...result?.data[0]["Purchase_Orders"]];
      let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
      let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
      skusl.map((sku, index)=>{
      cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
      cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
      })
      cal3=(cal3+parseFloat(result?.data[0]["Shipping_Cost"])+parseFloat(result?.data[0]["Tax"]))
      cal=(cal+parseFloat(result?.data[0]["Shipping_Cost"])+parseFloat(result?.data[0]["Tax"]))
      SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
      setDis(false)
        // if(searchParams?.get("RID")!=="") setDraftSubmitRFQIDDisable(true)
    }).catch((error)=>{
      setRFQMssge(error?.response?.data)
      setErrorDialog(true)
      navigate("/RFQsSearch")
    })
  
  }
  fetchData();
 
},[searchParams])

{/*---------------------UseEffect for assigning date from the table to the Usestates------------*/}


const handleClose = (e) => {
  setOpen(false);
  setErrorDialog(false)
  setSuccessDialog(false)
};
const handleSubmitOrSaveDecision=(e)=>{
  e.preventDefault()
  setAreYouSureDialog(true)
}
const handleAreYouSureDialogClose=(e)=>{
  setAreYouSureDialog(false)
}
{/*---------------------Adding SKUs-----------------*/}
const HandleAddSKU=()=>{
    Set_New_SKU([...New_SKUT,{Vendor_Partner_name:'',
    SKU_ID:'',
    SKU_Description:'',
    QTY:parseInt(0),
    Aspetto_Unit_Cost:parseFloat(0).toFixed(2),
    Unit_Of_Measure:'ea',
    RFQRFQID:Form_Value.RFQ_ID,
    Margin_Percent:parseFloat(0).toFixed(3),Customer_Unit_Cost:parseFloat(0).toFixed(3),
    Total_SKU_Cost:parseFloat(0).toFixed(3),
    RFQ_Created_Date:Form_Value.Created_Date,
    RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
    SKU_Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
    SKU_Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
    SKU_Created_By:Form_Value.Updated_By,
    SKU_Updated_By:Form_Value.Updated_By,                                                     
    Version_No:Form_Value.Version_No
}])
};

{/*---------------------Removing SKUs-----------------*/}
const HandleRemoveSKU=(index)=>{
   
    const skusl=[...New_SKUT];
    skusl.splice(index,1)
    Set_New_SKU(skusl)
    let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
    let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
    skusl.map((sku, index)=>{
    cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
    cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost)
    })
    cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax))
    cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3 ).toFixed(2),});
  }

{/*---------------------SKU changes-----------------*/}
const HandleSKUChange=(e,index)=>{
  const {name,value}=e.target;
  const skusl=[...New_SKUT];
  skusl[index][name]=value;
  skusl[index]['Customer_Unit_Cost']=parseFloat(skusl[index]['Aspetto_Unit_Cost']/(1-skusl[index]['Margin_Percent']/100)).toFixed(3);
  skusl[index]['Total_SKU_Cost']=parseFloat(parseInt(skusl[index]['QTY'])*parseFloat(skusl[index]['Customer_Unit_Cost'])).toFixed(3);
  skusl[index]['SKU_Updated_By']=UserEmail
  skusl[index]['SKU_Last_Updated_Date']=(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()))
  Set_New_SKU(skusl)
  let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
  let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
  skusl.map((sku, index)=>{
  cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
  cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
  })
  cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax))
  cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost))
  SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
}

const HandleCustomerCostChange=(e,index)=>{
  const skusl=[...New_SKUT];
  skusl[index]['Customer_Unit_Cost']=parseFloat(e.target.value)
  skusl[index]['Margin_Percent']=parseFloat((parseFloat((parseFloat(e.target.value)-skusl[index]['Aspetto_Unit_Cost']))/parseFloat(e.target.value))*100).toFixed(3);
  skusl[index]['Total_SKU_Cost']=parseFloat(parseInt(skusl[index]['QTY'])*parseFloat(parseFloat(e.target.value))).toFixed(3);
  skusl[index]['SKU_Updated_By']=UserEmail
  skusl[index]['SKU_Last_Updated_Date']=(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear()))
  Set_New_SKU(skusl)
  let cal=TotalCost.Actual_Total_Cost_Before_Margin===0;
  let cal3=TotalCost.Actual_Total_Cost_After_Margin===0;
  skusl.map((sku, index)=>{
  cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
  cal3= cal3+ parseInt(sku.QTY)*parseFloat(parseFloat(e.target.value));
  })
  cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(Form_Value.Tax))
  cal=(cal+parseFloat(Form_Value.Tax)+parseFloat(Form_Value.Shipping_Cost))
  SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
}
const handleShipping=(e)=>{
  if(shipp==true)
  {
  const skusl=[...New_SKUT];
    Set_Form_Value({...Form_Value,Shipping_Cost:e.target.value})
    let cal=0;
    let cal3=0;
    skusl.map((sku, index)=>{
    cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
    cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
    })
    cal3=(cal3+parseFloat(e.target.value)+parseFloat(Form_Value.Tax))
    cal=(cal+parseFloat(e.target.value)+parseFloat(Form_Value.Tax))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
  }
  else
  {
    const skusl=[...New_SKUT];
    Set_Form_Value({...Form_Value,Shipping_Cost:0})
    let cal=0;
    let cal3=0;
    skusl.map((sku, index)=>{
    cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
    cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
    })
    cal3=(cal3+parseFloat(e.target.value)+parseFloat(Form_Value.Tax))
    cal=(cal+parseFloat(e.target.value)+parseFloat(Form_Value.Tax))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
  }

}

const handleTax=(e)=>{
  if(tax==true)
  {
  const skusl=[...New_SKUT];
  Set_Form_Value({...Form_Value,Tax:e.target.value});
  let cal=0;
  let cal3=0;
    skusl.map((sku, index)=>{
    cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
    cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
    })
    cal=cal+(parseFloat(e.target.value)+parseFloat(Form_Value.Shipping_Cost))
    cal3=cal3+(parseFloat(e.target.value)+parseFloat(Form_Value.Shipping_Cost))
    SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
  }
  else
  {
    const skusl=[...New_SKUT];
    Set_Form_Value({...Form_Value,Tax:0});
    let cal=0;
    let cal3=0;
      skusl.map((sku, index)=>{
      cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
      cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Customer_Unit_Cost);
      })
      cal=cal+(parseFloat(e.target.value)+parseFloat(Form_Value.Shipping_Cost))
      cal3=cal3+(parseFloat(e.target.value)+parseFloat(Form_Value.Shipping_Cost))
      SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
  }
}

const [InvoiceData,setInvoiceData]=useState({ 
  invoice_no: '',
  KO_name:"",
  KO_email:"",
  KO_phone: "",
  KO_address:"",
  trans_date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
  due_date: "",
  Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
  items: [{}],
  Tax:0,
  Shipping_Cost:0,
  Total_Cost:0,
  Shipping_Details:{
    Organization_Name:"",
    KO_name:"",
    Sales_Head_Info:{},
    Customer_Name:"",
    Customer_Email:"",
    Customer_Phone:"",
    Customer_Address:"",
    Shipping_Method:"",
    Shipping_Terms:"",
    Estimated_Delivery:"",
    Payment_Terms:"",
    Notes:""}})

  {/*---------------------Submitting the form and sending the data-----------------*/}
  const HandleSubmit = async(event) => 
  {   
      event.preventDefault()
      Set_Form_Value({...Form_Value,Updated_By:UserAuth.UserEmail,Invoice_No:Form_Value?.Invoice_No,
        Last_Updated_Date:today})
      const Form_Table={...Form_Value,...TotalCost}
      setDis(true)
      setFileName(Form_Value.Opportunity_Name+" "+Form_Value.RFQ_ID)
      
      await AxiosAPIInstance.patch("/RFQs/UpdateRFQ",[Form_Table,New_SKUT,UserEmail])
      .then(
         async (response)=>{
        //------------------Setting PDF Data-------------------------
        setInvoiceData({...InvoiceData,
          invoice_num: response?.data,
          KO_name: Form_Value.KO_name,
          KO_email:Form_Value.KO_email,
          KO_phone: Form_Value.KO_phone,
          KO_address:Form_Value.KO_address,
          trans_date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
          due_date: Form_Value.Due_date,
          Address:'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
          items: [...New_SKUT],
          Tax:Form_Value.Tax,
          Shipping_Cost:Form_Value.Shipping_Cost,
          Total_Cost:TotalCost.Actual_Total_Cost_After_Margin,
          Shipping_Details:{
            Organization_Name:Form_Value.Organization_Name,
            KO_name:Form_Value.KO_name,
            Sales_Head_Info:{Name:Form_Value.Sales_Head_Name,Email:Form_Value.Sales_Head_Email,Phone:Form_Value.Sales_Head_Ph},
            Customer_Name:Form_Value.Customer_Name,
            Customer_Email:Form_Value.Customer_Email,
            Customer_Phone:Form_Value.Customer_Phone,
            Customer_Address:Form_Value.Customer_Address,
            Shipping_Method:Form_Value.Shipping_Method,
            Shipping_Terms:Form_Value.Shipping_Terms,
            Estimated_Delivery:Form_Value.Estimated_Delivery,
            Payment_Terms:Form_Value.Payment_Terms,
            Notes:Form_Value.Notes}
            })
            setClicked(true)
            setRFQMssge('RFQ ID: '+Form_Value.RFQ_ID+' Updated Sucessfully')
            setSuccessDialog(true)
            //-----------------------------Resetting the Form----------------------------------------------------------------------------------------
            Set_Form_Value({...Form_Value,Opportunity_Name:'',RFQ_ID:'',Contract_Vehicle:'GSA',KO_name:'',KO_email:'',
            KO_phone:'',KO_address:'',Published_date:null,Due_date:null,Brief:'',Organization_Name:'',Customer_Name:'',Customer_Email:'',Customer_Phone:'',Customer_Address:'',Shipping_Method:'',Sales_Head_Name:'',Sales_Head_Email:'',Sales_Head_Ph:"",
            Shipping_Terms:'FOB Origin',Estimated_Delivery:'',Payment_Terms:'NET30',Notes:'',Created_Date:'',
            Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),RFQ_Status:'PENDING',Updated_By:'TBD'})
            
            SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(0.00).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(0.00 ).toFixed(2),Discount_Applied:parseFloat(0).toFixed(2),
            Final_Total_Cost_After_Dicscount:parseFloat(0).toFixed(2),Shipping_Cost:parseFloat(0).toFixed(2),Tax:parseFloat(0).toFixed(2)})
          
            Set_New_SKU([{Vendor_Partner_name:'',SKU_ID:'',SKU_Description:'',QTY:parseInt(),Aspetto_Unit_Cost:parseFloat().toFixed(2),Unit_Of_Measure:'ea',RFQRFQID:'',Margin_Percent:parseFloat(0).toFixed(2),
            Customer_Unit_Cost:parseFloat(0.00).toFixed(2),Total_SKU_Cost:parseFloat().toFixed(2),
            RFQ_Created_Date:Form_Value.Created_Date,
            RFQ_Last_Updated_Date:Form_Value.Last_Updated_Date,
            SKU_Created_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
            SKU_Last_Updated_Date:(String(Number(PD.getUTCMonth())+1) +"-"+String(PD.getUTCDate()) +"-"+String(PD.getUTCFullYear())),
            SKU_Created_By:Form_Value.Sales_Head_Name,
            SKU_Updated_By:Form_Value.Sales_Head_Name,}])
            setDis(false)
       })
     .catch((err)=>{
      setRFQMssge(err.response?.data)
      setErrorDialog(true)
      setDis(false)
      })
  }

return(
  <>
 {<ApplicationBar></ApplicationBar>}
    {open===true&&(<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to exit this page?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the changes will be lost if you decide to exit this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button name="Yes" onClick={(e)=>{e.preventDefault();handleClose();navigate("/Dashboard")}}>Yes</Button>
        <Button name="No"  onClick={(e)=>{e.preventDefault();handleClose()}} autoFocus>
          No
        </Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfqMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
        {rfqMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          e.preventDefault();
          handleClose();
          navigate("/Dashboard/CreateRFQ", { replace: true });
          setDis(false)
          }
          } href="/Dashboard/CreateRFQ">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfqMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
         {rfqMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/CreateRFQ">OK</Button>
        </DialogActions>
      </Dialog>)}
<Paper name="RFQ_Form" component="form"  sx={{marginLeft:"65px",padding:"20px"}} autoComplete='off' elevation={0}>
{areYouSureDialog===true&&(<Dialog
        open={areYouSureDialog}
        onClose={handleAreYouSureDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{width:"400px"}}>
       <Alert variant='filled' severity='warning'> Are You Sure, You want to Update Changes?</Alert>
        </DialogTitle>
        <DialogContent sx={{width:"400px"}}>
          <DialogContentText id="alert-dialog-description">
          <b>Things to Remember:</b><br/>1. Your Revision Number will be updated if you have updated(added,removed,updated SKUs) Order Details<br/>
          2.Once Submited You Can only Access it from the Retrieve/Update RFQs Tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="YES" type='submit' onClick={async(e)=>{ setAreYouSureDialog(false);HandleSubmit(e)}} >YES</Button>
          <Button name="NO" onClick={(e)=>{e.preventDefault();setAreYouSureDialog(false)}}>
           NO
          </Button>
        </DialogActions>
      </Dialog>)}
    <Stack direction="row" spacing={2}  sx={{width:"600px"}} >
        <Stack direction="column" spacing={3} sx={{marginTop:"30px"}}>
        <Typography component="p" sx={{alignContent:"center",borderColor:'divider',fontSize:"25px", fontWeight:"bold",}} > Request For Quotation(RFQ)-Update</Typography>
          <Stack direction="row" spacing={2}> 
          <FormControl size="small" sx={{width:"250px"}}>
           <InputLabel id="STATUS">STATUS</InputLabel>
           <Select size="small"
          labelId="STATUS"
          id="STATUS"
          value={Form_Value.RFQ_Status}
          label="STATUS"
          onChange={(e)=>{Set_Form_Value({...Form_Value,RFQ_Status:e.target.value})}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="PENDING" value={"PENDING"}>PENDING</MenuItem>
          <MenuItem size="small" name="WON" value={"WON"}>WON</MenuItem>
          <MenuItem size="small" name="LOST" value={"LOST"}>LOST</MenuItem>
        </Select>
      </FormControl>
      <TextField size="small" required sx={{width:"250px"}} label="Updating Comments" multiline={true} value={Form_Value.Updating_Comments} onChange={(e)=>Set_Form_Value({...Form_Value,Updating_Comments:e.target.value})} minRows={2} 
      helperText="Ex: Status change for PENDING TO WON or Added/Removed/Updated SKUs. Minimum 2 lines required" ></TextField>
          </Stack>
        <Stack direction="row"  spacing={2}>
        <TextField size="small"  required variant="outlined" sx={{width:"250px"}} label="Opportunity Name" value={Form_Value.Opportunity_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Opportunity_Name:e.target.value})}></TextField>
        <TextField size="small" required sx={{width:"250px"}} label="RFQ ID" value={Form_Value.RFQ_ID}  InputProps={{readOnly:'true',endAdornment:(
            <InputAdornment>
      <Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>
            </InputAdornment>
          )}}></TextField>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
        <Select size="small"
          labelId="Contract-Vehicle"
          id="Contract-Vehicle-id"
          value={Form_Value.Contract_Vehicle}
          label="Contract Vehicle"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Contract_Vehicle:e.target.value})}}
          sx={{width:"250px"}}>
          <MenuItem size="small" name="G" value={"GSA"}>GSA</MenuItem>
          <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
          <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Direct Inquiry</MenuItem>
          <MenuItem size="small" name="CR" value={"Commercial/Retail"}>Commercial/Retail</MenuItem>
        </Select>
      </FormControl>
        <TextField size="small" required sx={{width:"250px"}} label="Contracting Officer's Name" value={Form_Value.KO_name} onChange={(e)=>Set_Form_Value({...Form_Value,KO_name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" required type="email" sx={{width:"250px"}} label="Contracting Officer's Email" value={Form_Value.KO_email} onChange={(e)=>Set_Form_Value({...Form_Value,KO_email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        </Stack>
        <Stack required direction="row" spacing={2}>
        <TextField size="small" required  sx={{width:"250px"}} label="Contracting Officer's Phone" value={Form_Value.KO_phone} onChange={(e)=>Set_Form_Value({...Form_Value,KO_phone:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" required multiline={true} rowsMax={10} sx={{width:"250px"}} label="Contracting Officer's Address" value={Form_Value.KO_address} onChange={(e)=>Set_Form_Value({...Form_Value,KO_address:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <DatePicker label="Published date" sx={{width:"250px"}} value={Form_Value.Published_date} maxDate={new Date()} renderInput={(params)=><TextField size="small" required sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Published_date:value})}>
        </DatePicker>
        <DatePicker label="Due date" sx={{width:"250px"}} value={Form_Value.Due_date} minDate={new Date()} renderInput={(params)=><TextField size="small" required variant="outlined" sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Due_date:value})}>
        </DatePicker>
        <TextField size="small" required sx={{width:"250px"}} multiline={true} rowsMax={20} label="Brief" value={Form_Value.Brief} onChange={(e)=>Set_Form_Value({...Form_Value,Brief:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction='row' spacing={2}>
        <TextField size="small" required sx={{width:"250px"}} label="Organization/Agency's Name" value={Form_Value.Organization_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Organization_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}></TextField>
          <TextField size="small" required sx={{width:"250px"}} label="Customer's Name" value={Form_Value.Customer_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Customer_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" required type="email" sx={{width:"250px"}} label="Customer's Email" value={Form_Value.Customer_Email} onChange={(e)=>Set_Form_Value({...Form_Value,Customer_Email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField size="small" required  sx={{width:"250px"}} label="Customer's Phone" value={Form_Value.Customer_Phone} onChange={(e)=>Set_Form_Value({...Form_Value,Customer_Phone:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
             <TextField size="small" required  sx={{width:"250px"}} label="Shipping Method"  value={Form_Value.Shipping_Method} onChange={(e)=>Set_Form_Value({...Form_Value,Shipping_Method:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocalShippingIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction='row' spacing={2}>

           <TextField size="small" required multiline={true} rowsMax={10} sx={{width:"250px"}} label="Customer's Address" value={Form_Value.Customer_Address} onChange={(e)=>Set_Form_Value({...Form_Value,Customer_Address:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
          </TextField>
        <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Shipping-Terms">Shipping Terms</InputLabel>
        <Select size="small"
          labelId="Shipping-Terms"
          id="Shipping-Terms-id"
          label="Shipping Terms"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Shipping_Terms:e.target.value})}}
          sx={{width:"250px"}}
          value={Form_Value.Shipping_Terms}>
          <MenuItem  size="small" value={"FOB Origin"}>FOB Origin</MenuItem>
          <MenuItem  size="small" value={"FOB Destination"}>FOB Destination</MenuItem>
        </Select>
      </FormControl>
      
      <TextField size="small" required multiline={true} rowsMax={10} sx={{width:"250px"}} label="Estimated Delivery" value={Form_Value.Estimated_Delivery} onChange={(e)=>Set_Form_Value({...Form_Value,Estimated_Delivery:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
          </TextField>
      <FormControl size="small" sx={{width:"250px"}}>
        <InputLabel id="Payment_Terms">Payment Terms</InputLabel>
        <Select size="small"
          labelId="Payment_Terms"
          id="Payment_Terms-id"
          value={Form_Value.Payment_Terms}
          label="Payment Terms"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Payment_Terms:e.target.value})}}
          sx={{width:"250px"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonetizationOnIcon />
              </InputAdornment>
            ),
          }}>
          <MenuItem size="small" value={"NET10"}>NET10</MenuItem>
          <MenuItem size="small" value={"NET20"}>NET20</MenuItem>
          <MenuItem size="small" value={"NET30"}>NET30</MenuItem>
          <MenuItem size="small" value={"NET40"}>NET45</MenuItem>
          <MenuItem size="small" value={"NET50"}>NET60</MenuItem>
        </Select>
      </FormControl>
        <TextField size="small" required sx={{width:"250px"}} name="Notes" label="Shipping Notes" multiline={true} rowsMax={20} value={Form_Value.Notes} onChange={(e)=>Set_Form_Value({...Form_Value,Notes:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        </Stack>
        <Stack direction='row' spacing={2}>
          <TextField size="small" required sx={{width:"250px"}} disabled label="Sales Lead Name" value={Form_Value.Sales_Head_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),endAdornment:(
            <InputAdornment>
      <Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>
            </InputAdornment>)
        }}></TextField>
        <TextField size="small"  required type="email" sx={{width:"250px"}} disabled label="Sales Lead Email" value={Form_Value.Sales_Head_Email} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),endAdornment:(
            <InputAdornment>
      <Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>
            </InputAdornment>)
        }}>
        </TextField>
        <TextField size="small" required  sx={{width:"250px"}} disabled label="Sales Lead Phone" value={Form_Value.Sales_Head_Ph} onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Ph:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),endAdornment:(
            <InputAdornment>
      <Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>
            </InputAdornment>)
        }}></TextField>
         <TextField size="small"  disabled required type="email" sx={{width:"250px"}} label="Updated By" value={UserEmail}  InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),endAdornment:(
            <InputAdornment>
      <Tooltip title='This Field is locked'><LockIcon></LockIcon></Tooltip>
            </InputAdornment>)
        }}>
        </TextField>
        </Stack>
  {/*--------------------------ORDER DETAILS-----------------------------------------------*/}
        <Stack direction="column" spacing={3}>
      <Typography variant="h5">Order Details</Typography>
       {New_SKUT?.map((New_Sku,index)=>{return(
      <Stack key={index} direction="column" spacing={1}>
      <Stack direction="row" spacing={2}>
      <TextField size="small"  required variant="outlined" name="Vendor_Partner_name" sx={{width:"150px"}} label="Vendor's Partner Name" value={New_Sku.Vendor_Partner_name} onChange={(e)=>HandleSKUChange(e,index)}></TextField>
        <TextField size="small" required variant="outlined" name="SKU_ID" sx={{width:"150px"}} label="SKU ID" value={New_Sku.SKU_ID} onChange={(e)=>HandleSKUChange(e,index)}></TextField>
        <TextField size="small" required sx={{width:"250px"}} name="SKU_Description" label="SKU Description" multiline={true} rowsMax={20} value={New_Sku.SKU_Description} onChange={(e)=>HandleSKUChange(e,index)}  InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField size="small" name="QTY" type="number" required variant="outlined" sx={{width:"100px"}} label="QTY" value={New_Sku.QTY} onChange={(e)=>HandleSKUChange(e,index)} inputProps={{min:"0",inputMode:"numeric"}} ></TextField>
        <TextField size="small" required variant="outlined" name="Unit_Of_Measure"  sx={{width:"70px"}} label="UM" value={New_Sku.Unit_Of_Measure} onChange={(e)=>HandleSKUChange(e,index) }></TextField>
        <TextField size="small" name="Aspetto_Unit_Cost" required sx={{width:"130px"}}  type="number"  inputProps={{min:"0",step:"any",inputMode:"numeric"}} label="Aspetto's Cost" value={New_Sku.Aspetto_Unit_Cost} onChange={(e)=>HandleSKUChange(e,index)} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          )}}></TextField>
           <TextField size="small" required variant="outlined" type="number" step="any" name="Margin_Percent"sx={{width:"110px"}} label="Margin" value={New_Sku.Margin_Percent} 
        onChange={(e)=>HandleSKUChange(e,index)} inputProps={{min:"0",step:"any"}} InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PercentIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField size="small" name="Customer_Unit_Cost" type="number" required sx={{width:"130px"}} label="Customer's Cost" value={New_Sku.Customer_Unit_Cost} onChange={(e)=>HandleCustomerCostChange(e,index)} inputProps={{min:"0",step:"any"}} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          )}}></TextField>
            <TextField size="small" name="Total_SKU_Cost"  required sx={{width:"180px"}} type="number" label="Total SKU Cost" value={New_Sku.Total_SKU_Cost} inputProps={{min:"0",step:"any"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          )}}></TextField>

        </Stack>
        <Stack direction="row" spacing={4}>
        {New_SKUT.length >1 && ( <Button size="small"  variant="outlined" color="error" onClick={()=>HandleRemoveSKU(index)}>Remove SKU</Button>)} 
        {New_SKUT.length-1===index && (<Button size="small" variant="contained"  color="success" onClick={()=>HandleAddSKU(index)}>Add New SKU</Button>)}
            </Stack>
        </Stack>)})}
   
        <Stack direction="row" spacing={2}>
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"205px"}}></Stack>
        <FormGroup>
        
      <FormControlLabel size="small" control={<Checkbox size="small" checked={shipp} onChange={(e)=>{
        setShipp(e.target.checked)
       if(e.target.checked===false)
       {
         Set_Form_Value({...Form_Value,Shipping_Cost:0})
         const skusl=[...New_SKUT];
         let cal=0;
         let cal3=0;
           skusl.map((sku, index)=>{
           cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
           cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost)*parseFloat((1+sku.Margin_Percent/100));
           })
           cal3=(cal3+parseFloat(0)+parseFloat(Form_Value.Tax))
           cal=(cal+parseFloat(0)+parseFloat(Form_Value.Tax))
           SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});
       }
      }}/>} label="Add Shipping Cost" />
       </FormGroup>
      <TextField required sx={{width:"200px"}} size="small" type ="number" disabled={!shipp} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Shipping cost" value={Form_Value.Shipping_Cost}  onChange={(e)=>handleShipping(e)}></TextField>
    
          </Stack>
          
          <Stack direction="row" spacing={2}>
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <Stack sx={{width:"280px"}}></Stack>
        <FormGroup>
      <FormControlLabel size="small" control={<Checkbox size="small" checked={tax} onChange={(e)=>{
        setTax(e.target.checked);
        if(e.target.checked===false)
        {
          Set_Form_Value({...Form_Value,Tax:0})
          const skusl=[...New_SKUT];
          let cal=0;
          let cal3=0;
            skusl.map((sku, index)=>{
            cal=cal + (parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost));
            cal3= cal3+ parseInt(sku.QTY)*parseFloat(sku.Aspetto_Unit_Cost)*parseFloat((1+sku.Margin_Percent/100));
            })
            cal3=(cal3+parseFloat(Form_Value.Shipping_Cost)+parseFloat(0))
            cal=(cal+parseFloat(Form_Value.Shipping_Cost)+parseFloat(0))
            SetTotalCost({...TotalCost,Actual_Total_Cost_Before_Margin:parseFloat(cal).toFixed(2),Actual_Total_Cost_After_Margin:parseFloat(cal3).toFixed(2),});

        }
      }}/>} label="Add Tax" />
       </FormGroup>
        <TextField required sx={{width:"200px"}}  size="small" disabled={!tax} InputProps={{startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Tax" value={Form_Value.Tax} type ="number" onChange={(e)=>handleTax(e)}></TextField>
   
          </Stack>
     <Stack direction="row" spacing={2}>
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"140px"}}></Stack><Stack sx={{width:"150px"}}></Stack>
        <TextField size="small"  required sx={{width:"300px"}} type="number" label="Total Cost Before Margin" value={TotalCost.Actual_Total_Cost_Before_Margin} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} ></TextField>
        <TextField size="small" required sx={{width:"300px"}} InputProps={{readOnly:true,startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon />
            </InputAdornment>
          ),}} label="Total Cost After Margin" value={TotalCost.Actual_Total_Cost_After_Margin} ></TextField>
        </Stack>
        
        
      </Stack>
        <Stack direction="row" spacing={2}>
        <Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"200px"}}></Stack><Stack sx={{width:"110px"}}></Stack>
        <Button sx={{width:"200px"}} variant="contained" color="error" onClick={(e)=>{handleSubmitOrSaveDecision(e)}} disabled={dis} > UPDATE</Button>
        <Stack sx={{width:"200px"}}>
          { 
         clicked===true &&
            (<PDFDownloadLink component="button" color="secondary"
            document={<PdfDocument invoicedata={InvoiceData}/>}
            fileName={fileName}>
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : "Download an Invoice"
            }
          </PDFDownloadLink>)
        }
  
        </Stack>
        </Stack>
      </Stack>
   
        </Stack>
</Paper>
</>
)
}
export default RFQ_CLIENT_INPUT_UPDATE